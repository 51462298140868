/* eslint-disable */
<template>
  <div>
    <!-- Header to display if no categories were selected in English-->
    <div
      class="topSideNoCats"
      v-if="this.categories.length == 0 && language == 'EN'"
    >
      <div class="row justify-center q-pa-md">
        <div class="col-12">
          <q-btn
            flat
            icon="arrow_back_ios"
            label="RESTART"
            color="secondary"
            @click="startover = true"
            style="float: left"
          ></q-btn>
        </div>

        <div
          class="col-12 text-center q-py-lg col-lg-8 col-md-8 col-sm-12 col-xs-12"
        >
          <!-- Top Banner  -->
          <q-banner
            class="foundationFont q-pa-lg"
            style="background-color: transparent; color: #fff"
          >
            <span class="headerText">
              The check up tool was unable to identify a legal area of need.
              Please explore the website to find information and resources.
              <!-- Format User Categories Logically -->
              <div
                class="text-bold q-pt-xl"
                style="font-size: 4vh; line-height: 1.25"
              >
                <q-btn
                  class="q-ma-md"
                  label="Visit Legal Needs Page"
                  color="primary"
                  size="1.25rem"
                  @click="goToLegalInfo()"
                ></q-btn>

                <q-btn
                  class="q-ma-md"
                  label="Visit Find Help Page"
                  color="primary"
                  size="1.25rem"
                  @click="goToFindHelp()"
                ></q-btn>
              </div>
              <br />
            </span>
          </q-banner>
        </div>
      </div>

      <q-dialog v-model="startover" persistent>
        <q-card>
          <q-card-actions align="right">
            <q-btn
              class
              flat
              label="X"
              size="1.25rem"
              color="primary"
              v-close-popup
              @click="startover = false"
            />
          </q-card-actions>
          <q-card-section class="row items-center" style="margin-top: -30px">
            <div
              class="q-ma-none q-pa-none text-center"
              style="color: #00667e; margin: auto"
            >
              <h5 class="text-bold" style="height: 0px">Are you sure?</h5>
            </div>
            <div class="q-pa-none q-ma-sm text-center reportModal">
              <h5 style="color: #00667e; margin-block-start: inherit">
                Upon restarting all of your current answers will be deleted. Are
                you sure you want to restart?
              </h5>
            </div>
          </q-card-section>
          <q-separator color="seconday" inlet />
          <q-card-section class="text-center">
            <q-btn
              flat
              label="Restart"
              color="primary"
              size="1.25rem"
              @click="goBack()"
              style="margin: auto"
            ></q-btn>
          </q-card-section>
        </q-card>
      </q-dialog>
      <!-- End of header with no categories -->
    </div>
    <!-- Header to display if no categories were selected in Spanish-->
    <div
      class="topSideNoCats"
      v-else-if="this.categories.length == 0 && language == 'ES'"
    >
      <div class="row justify-center q-pa-md">
        <div class="col-12">
          <q-btn
            flat
            icon="arrow_back_ios"
            label="REINICIAR"
            color="secondary"
            @click="startover = true"
            style="float: left"
          ></q-btn>
        </div>

        <div
          class="col-12 text-center q-py-lg col-lg-8 col-md-8 col-sm-12 col-xs-12"
        >
          <!-- Top Banner  -->
          <q-banner
            class="foundationFont q-pa-lg"
            style="background-color: transparent; color: #fff"
          >
            <span class="headerText">
              La herramienta de verificación no pudo identificar un área legal
              de necesidad. Explore el sitio web para encontrar información y
              recursos.
              <!-- Format User Categories Logically -->
              <div
                class="text-bold q-pt-xl"
                style="font-size: 4vh; line-height: 1.25"
              >
                <q-btn
                  class="q-ma-md"
                  label="Visite la página de necesidades legales"
                  color="primary"
                  size="1.25rem"
                  @click="goToLegalInfo()"
                ></q-btn>

                <q-btn
                  class="q-ma-md"
                  label="Visite la página buscar ayuda"
                  color="primary"
                  size="1.25rem"
                  @click="goToFindHelp()"
                ></q-btn>
              </div>
              <br />
            </span>
          </q-banner>
        </div>
      </div>

      <q-dialog v-model="startover" persistent>
        <q-card>
          <q-card-actions align="right">
            <q-btn
              class
              flat
              label="X"
              size="1.5rem"
              color="primary"
              v-close-popup
              @click="startover = false"
            />
          </q-card-actions>
          <q-card-section class="row items-center" style="margin-top: -30px">
            <div
              class="q-ma-none q-pa-none text-center"
              style="color: #00667e; margin: auto"
            >
              <h5 class="text-bold" style="height: 0px">¿Estas seguro?</h5>
            </div>
            <div class="q-pa-none q-ma-sm text-center reportModal">
              <h5 style="color: #00667e; margin-block-start: inherit">
                Al reiniciar, se eliminarán todas sus respuestas actuales.
                ¿Estás seguro de que quieres reiniciar?
              </h5>
            </div>
          </q-card-section>
          <q-separator color="secondary" inlet />
          <q-card-section class="text-center">
            <q-btn
              flat
              label="Reiniciar"
              color="primary"
              size="1.25rem"
              @click="goBack()"
              style="margin: auto"
            ></q-btn>
          </q-card-section>
        </q-card>
      </q-dialog>
      <!-- End of header with no categories in spanish-->
    </div>

    <div class v-else>
      <div class="topSide">
        <!-- Section to display back button, exit button, and report header in English -->
        <div v-if="language == 'EN'" class="row items-center justify-between">
          <div class="col-4 q-pa-xs">
            <q-btn
              color="secondary"
              flat
              icon="arrow_back_ios"
              label="BACK"
              @click="restart = true"
            ></q-btn>
          </div>
          <div class="col-4"></div>
          <div class="col-4 q-pa-xs">
            <q-btn
              class=""
              color="secondary"
              flat
              label="Exit"
              icon-right="close"
              @click="exit"
              style="float: right"
            ></q-btn>
          </div>

          <div
            class="col-lg-9 col-md-9 col-sm-12 col-xs-12 q-py-xl q-my-xl"
            style="margin: auto"
          >
            <!-- Top Banner  -->
            <q-banner style="text-align: center">
              <div class="foundationFont headerText">
                Based on your answers, a report on the following topics has been
                prepared:
                <!-- Format User Categories Logically -->
                <div
                  class="text-bold text-h4 q-pt-md headerCategories"
                  style="margin: auto"
                >
                  {{ category_text }}
                </div>
                <!-- has been prepared below. -->
                <div class="q-pa-sm headerText">
                  Click through each section to find information and resources.
                  The information below is for general legal information
                  purposes only and should not be considered legal advice.
                </div>
              </div>

              <div class v-if="loading">
                <q-spinner-ios color="primary" size="4vh" />
              </div>
            </q-banner>
          </div>
        </div>
        <!-- End header in English -->

        <!-- Section to display back button, exit button, and report header in Spanish -->
        <div v-if="language == 'ES'" class="row items-center justify-between">
          <div class="col-6 q-pa-xs">
            <q-btn
              color="secondary"
              flat
              icon="arrow_back_ios"
              label="REINICIAR"
              @click="restart = true"
            ></q-btn>
          </div>
          <div class="col-6 q-pa-xs">
            <q-btn
              class=""
              color="secondary"
              flat
              label="SALIDA"
              icon-right="close"
              @click="exit"
              style="float: right"
            ></q-btn>
          </div>

          <div
            class="col-12 text-center col-lg-9 col-md-9 col-sm-12 col-xs-12"
            style="margin: auto"
          >
            <!-- Top Banner  -->
            <q-banner class="foundationFont" style="text-align-center">
              <div class="headerText">
                Sobre la base de sus respuestas, se ha elaborado un informe
                sobre los siguientes temas:
                <!-- Format User Categories Logically -->
                <div
                  class="text-bold text-h4 q-pt-md headerCategories"
                  style="margin: auto"
                >
                  {{ category_text }}
                </div>
                <!-- has been prepared below. -->
                <br />
                <div class="q-pa-sm headerText">
                  <p>
                    Haga clic en cada sección para encontrar información y
                    recursos. La siguiente información es solo para fines de
                    información legal general y no debe considerarse
                    asesoramiento legal.
                  </p>
                </div>
              </div>

              <div class v-if="loading">
                <q-spinner-ios color="primary" size="5vh" />
              </div>
            </q-banner>
          </div>
        </div>
        <!-- End header in Spanish -->

        <!-- Download button  -->
        <div
          class="col-12 q-my-lg downloadPosition"
          style="text-align: end; width: 89.25%"
        >
          <q-btn
            v-if="language == 'EN'"
            class="downloadBtn"
            :disabled="!fileURL.includes('blob')"
            no-caps
            v-bind:class="{}"
            style="font-size: 1.25rem"
            color="primary"
            icon="get_app"
            label="Download as PDF"
            @click="openPDF(); track('Open PDF', 'EN')"
          ></q-btn>
          <q-btn
            v-if="language == 'ES'"
            class="downloadBtn"
            :disabled="!fileURL.includes('blob')"
            no-caps
            v-bind:class="{}"
            style="font-size: 1.25rem"
            color="primary"
            icon="get_app"
            label="Descarga Este Informe"
            @click="openPDF(); track('Open PDF', 'ES')"
          ></q-btn>
        </div>
      </div>

      <!-- Section to display user report -->
      <div
        v-if="$q.platform.is.desktop"
        class="row items-center justify-center"
      >
        <div class="bottomSide">
          <div
            v-if="categories.length != 0"
            style="
              max-width: 80%;
              max-height: 100%;
              margin-left: auto;
              margin-right: auto;
            "
            class="justify-center items-center"
          >
            <!-- Expansion Items -->
            <div class="q-pa-sm text-center" style>
              <q-list style="">
                <q-expansion-item
                  style="max-width: 100%; max-height: 100% "
                  :value="expanded == category"
                  @click="
                    () => {
                      expanded = category;
                      $refs.stepper[stepperTracker[category]].goTo(1);
                    }
                  "
                  class="q-pa-sm"
                  v-for="category in categories"
                  :key="category"
                  :group="category"
                  :header-style="{ height: '4rem' }"
                  expand-separator
                  expand-icon-class="text-white"
                  @show="
                    () => {
                      topic = 'about';
                    }
                  "
                  header-class="bg-primary text-h4 text-center text-bold"
                >
                  <template v-slot:header>
                    <q-item-section
                      style="font-size: 3vh; color: #fff"
                      class="foundationFont"
                      >{{ category }}</q-item-section
                    >
                  </template>

                  <q-card v-if="category == 'Other' || category == 'Otro'">
                    <!-- Other section for English -->
                    <div
                      v-if="language == 'EN'"
                      class="col-12 row q-pa-md items-center justify-center"
                    >
                      <!-- left column in other section -->
                      <div class="col-6 text-center">
                        <h5
                          class="text-bold"
                          style="color: #00667e; width: 60%; margin: auto"
                        >
                          Visit these links to find more information on topics
                          related to your answers:
                        </h5>
                      </div>
                      <!-- Right column in other section-->
                      <div class="col-6">
                        <div class="col-4 q-pa-sm">
                          <q-btn
                            flat
                            icon-right="keyboard_arrow_right"
                            @click="
                              openUrl(
                                'https://azcrimevictimhelp.org/other/dating-violence'
                              )
                            "
                            class="otherBtn"
                            color="primary"
                            size="1.5rem"
                            label="Domestic Violence"
                            no-caps
                            align="left"
                            style="font-weight: bold"
                          />
                        </div>
                        <div class="col-4 q-pa-sm">
                          <q-btn
                            flat
                            icon-right="keyboard_arrow_right"
                            @click="
                              openUrl(
                                'https://azcrimevictimhelp.org/other/sexual-violence'
                              )
                            "
                            class="otherBtn"
                            color="primary"
                            size="1.5rem"
                            label="Sexual Violence"
                            no-caps
                            align="left"
                            style="font-weight: bold"
                          />
                        </div>
                        <div class="col-4 q-pa-sm">
                          <q-btn
                            flat
                            icon-right="keyboard_arrow_right"
                            @click="
                              openUrl(
                                'https://azcrimevictimhelp.org/other/elder-abuse'
                              )
                            "
                            class="otherBtn"
                            color="primary"
                            size="1.5rem"
                            label="Elder Abuse"
                            no-caps
                            align="left"
                            style="font-weight: bold"
                          />
                        </div>
                        <div class="col-4 q-pa-sm">
                          <q-btn
                            flat
                            icon-right="keyboard_arrow_right"
                            @click="
                              openUrl(
                                'https://azcrimevictimhelp.org/other/child-abuse'
                              )
                            "
                            class="otherBtn"
                            color="primary"
                            size="1.5rem"
                            label="Child Abuse"
                            no-caps
                            align="left"
                            style="font-weight: bold"
                          />
                        </div>
                        <div class="col-4 q-pa-sm">
                          <q-btn
                            flat
                            icon-right="keyboard_arrow_right"
                            @click="
                              openUrl(
                                'https://azcrimevictimhelp.org/other/human-trafficking'
                              )
                            "
                            class="otherBtn"
                            color="primary"
                            size="1.5rem"
                            label="Human Trafficking"
                            no-caps
                            align="left"
                            style="font-weight: bold"
                          />
                        </div>
                        <div class="col-4 q-pa-sm">
                          <q-btn
                            flat
                            icon-right="keyboard_arrow_right"
                            @click="
                              openUrl(
                                'https://azcrimevictimhelp.org/other/Stalking'
                              )
                            "
                            class="otherBtn"
                            color="primary"
                            size="1.5rem"
                            label="Stalking"
                            no-caps
                            align="left"
                            style="font-weight: bold"
                          />
                        </div>
                        <div class="col-4 q-pa-sm">
                          <q-btn
                            flat
                            icon-right="keyboard_arrow_right"
                            @click="
                              openUrl(
                                'https://azcrimevictimhelp.org/other/Bullying'
                              )
                            "
                            class="otherBtn"
                            color="primary"
                            size="1.5rem"
                            label="Bullying"
                            no-caps
                            align="left"
                            style="font-weight: bold"
                          />
                        </div>
                        <div class="col-4 q-pa-sm">
                          <q-btn
                            flat
                            icon-right="keyboard_arrow_right"
                            @click="
                              openUrl(
                                'https://azcrimevictimhelp.org/other/tech-crimes'
                              )
                            "
                            class="otherBtn"
                            color="primary"
                            size="1.5rem"
                            label="Tech Crimes"
                            no-caps
                            align="left"
                            style="font-weight: bold"
                          />
                        </div>
                      </div>
                    </div>
                    <!-- Other section for Spanish -->
                    <div
                      v-if="language == 'ES'"
                      class="col-12 row q-pa-md items-center justify-center"
                    >
                      <!-- left column in other section -->
                      <div class="col-6 text-center">
                        <h5
                          class="text-bold"
                          style="color: #00667e; width: 60%; margin: auto"
                        >
                          Visite estos enlaces para encontrar más información
                          sobre temas relacionados con sus respuestas:
                        </h5>
                      </div>
                      <!-- Right column in other section-->
                      <div class="col-6">
                        <div class="col-4 q-pa-sm">
                          <q-btn
                            flat
                            icon-right="keyboard_arrow_right"
                            @click="
                              openUrl(
                                'https://azcrimevictimhelp.org/other/dating-violence'
                              )
                            "
                            class="otherBtn"
                            color="primary"
                            size="1.5rem"
                            label="Violencia Doméstica"
                            no-caps
                            align="left"
                            style="font-weight: bold"
                          />
                        </div>
                        <div class="col-4 q-pa-sm">
                          <q-btn
                            flat
                            icon-right="keyboard_arrow_right"
                            @click="
                              openUrl(
                                'https://azcrimevictimhelp.org/other/sexual-violence'
                              )
                            "
                            class="otherBtn"
                            color="primary"
                            size="1.5rem"
                            label="Violencia Sexual"
                            no-caps
                            align="left"
                            style="font-weight: bold"
                          />
                        </div>
                        <div class="col-4 q-pa-sm">
                          <q-btn
                            flat
                            icon-right="keyboard_arrow_right"
                            @click="
                              openUrl(
                                'https://azcrimevictimhelp.org/other/elder-abuse'
                              )
                            "
                            class="otherBtn"
                            color="primary"
                            size="1.5rem"
                            label="Maltrato de Mayores"
                            no-caps
                            align="left"
                            style="font-weight: bold"
                          />
                        </div>
                        <div class="col-4 q-pa-sm">
                          <q-btn
                            flat
                            icon-right="keyboard_arrow_right"
                            @click="
                              openUrl(
                                'https://azcrimevictimhelp.org/other/child-abuse'
                              )
                            "
                            class="otherBtn"
                            color="primary"
                            size="1.5rem"
                            label="Abuso Infantil"
                            no-caps
                            align="left"
                            style="font-weight: bold"
                          />
                        </div>
                        <div class="col-4 q-pa-sm">
                          <q-btn
                            flat
                            icon-right="keyboard_arrow_right"
                            @click="
                              openUrl(
                                'https://azcrimevictimhelp.org/other/human-trafficking'
                              )
                            "
                            class="otherBtn"
                            color="primary"
                            size="1.5rem"
                            label="Trata de Personas"
                            no-caps
                            align="left"
                            style="font-weight: bold"
                          />
                        </div>
                        <div class="col-4 q-pa-sm">
                          <q-btn
                            flat
                            icon-right="keyboard_arrow_right"
                            @click="
                              openUrl(
                                'https://azcrimevictimhelp.org/other/Stalking'
                              )
                            "
                            class="otherBtn"
                            color="primary"
                            size="1.5rem"
                            label="Acecho"
                            no-caps
                            align="left"
                            style="font-weight: bold"
                          />
                        </div>
                        <div class="col-4 q-pa-sm">
                          <q-btn
                            flat
                            icon-right="keyboard_arrow_right"
                            @click="
                              openUrl(
                                'https://azcrimevictimhelp.org/other/Bullying'
                              )
                            "
                            class="otherBtn"
                            color="primary"
                            size="1.5rem"
                            label="Acoso"
                            no-caps
                            align="left"
                            style="font-weight: bold"
                          />
                        </div>
                        <div class="col-4 q-pa-sm">
                          <q-btn
                            flat
                            icon-right="keyboard_arrow_right"
                            @click="
                              openUrl(
                                'https://azcrimevictimhelp.org/other/tech-crimes'
                              )
                            "
                            class="otherBtn"
                            color="primary"
                            size="1.5rem"
                            label="Delitos Tecnológicos"
                            no-caps
                            align="left"
                            style="font-weight: bold"
                          />
                        </div>
                      </div>
                    </div>
                  </q-card>

                  <q-card v-else class="" >
                    <div class="row" >
                      <div class="col-12 shadow-1">
                        <div class="row q-pa-none">
                          <div
                            class="col-3 q-px-none"
                            style="background-color: #82b8c4"
                          >
                            <!-- Summary button in English -->
                            <q-btn
                              v-if="language == 'EN'"
                              class="foundationFont notranslate full-width text-bold shadow-1"
                              flat
                              active="primary"
                              v-bind:class="{
                                activeBtnMobile: topic == 'about',
                              }"
                              style="font-size: 1rem"
                              color="white"
                              label="SUMMARY"
                              @click="makeActive($event, 'about')"
                            />
                            <!-- Summary button in Spanish -->
                            <q-btn
                              v-if="language == 'ES'"
                              class="foundationFont notranslate full-width text-bold shadow-1"
                              flat
                              v-bind:class="{
                                activeBtnMobile: topic == 'about',
                              }"
                              style="font-size: 1rem"
                              color="white"
                              label="RESUMEN"
                              @click="makeActive($event, 'about')"
                            />
                          </div>

                          <div
                            class="col-3 q-px-none"
                            style="background-color: #82b8c4"
                          >
                            <!-- Next Steps button in English -->
                            <q-btn
                              v-if="language == 'EN'"
                              class="foundationFont notranslate full-width text-bold shadow-1"
                              no-caps
                              flat
                              v-bind:class="{
                                activeBtnMobile: topic == 'nextSteps',
                              }"
                              style="font-size: 1rem"
                              color="white"
                              label="NEXT STEPS"
                              @click="makeActive($event, 'nextSteps')"
                            />
                            <!-- Next Steps button in Spanish -->
                            <q-btn
                              v-if="language == 'ES'"
                              class="foundationFont notranslate full-width text-bold shadow-1"
                              no-caps
                              flat
                              v-bind:class="{
                                activeBtnMobile: topic == 'nextSteps',
                              }"
                              style="font-size: 1rem"
                              color="white"
                              label="PRÓXIMOS PASOS"
                              @click="makeActive($event, 'nextSteps')"
                            />
                          </div>

                          <div
                            class="col-3 q-px-none"
                            style="background-color: #82b8c4"
                          >
                            <!-- Resources button in English -->
                            <q-btn
                              v-if="language == 'EN'"
                              class="foundationFont notranslate full-width text-bold shadow-1"
                              no-caps
                              flat
                              v-bind:class="{
                                activeBtnMobile: topic == 'findHelp',
                              }"
                              style="font-size: 1rem"
                              color="white"
                              label="RESOURCES"
                              @click="makeActive($event, 'findHelp')"
                            />
                            <!-- Resources button in Spanish -->
                            <q-btn
                              v-if="language == 'ES'"
                              class="foundationFont notranslate full-width text-bold shadow-1"
                              no-caps
                              flat
                              v-bind:class="{
                                activeBtnMobile: topic == 'findHelp',
                              }"
                              style="font-size: 1rem"
                              color="white"
                              label="RECURSOS"
                              @click="makeActive($event, 'findHelp')"
                            />
                          </div>

                          <div
                            class="col-3 q-px-none"
                            style="background-color: #82b8c4"
                          >
                            <!-- More info button in English -->
                            <q-btn
                              v-if="language == 'EN'"
                              class="foundationFont notranslate full-width text-bold shadow-1"
                              no-caps
                              flat
                              v-bind:class="{
                                activeBtnMobile: topic == 'moreInfo',
                              }"
                              style="font-size: 1rem"
                              color="white"
                              label="MORE INFO"
                              @click="makeActive($event, 'moreInfo')"
                            />
                            <!-- More info button in Spanish -->
                            <q-btn
                              v-if="language == 'ES'"
                              class="foundationFont notranslate full-width text-bold shadow-1"
                              no-caps
                              flat
                              v-bind:class="{
                                activeBtnMobile: topic == 'moreInfo',
                              }"
                              style="font-size: 1rem"
                              color="white"
                              label="MÁS INFORMACIÓN"
                              @click="makeActive($event, 'moreInfo')"
                            />
                          </div>
                        </div>
                      </div>
                      <div class="col-12 content-areaMobile" color="accent">
                        <!-- About your situation-->
                        <q-card-section v-if="topic == 'about'">
                          <div class="flex flex-center">
                            <!-- English -->
                            <p
                              v-if="language == 'EN'"
                              class="text-bold q-pt-lg"
                              style="font-size: 1.5rem; color: #00667e"
                            >
                              About Your Situation
                            </p>
                            <!-- Spanish -->
                            <p
                              v-if="language == 'ES'"
                              class="text-bold q-pt-lg"
                              style="font-size: 1.5rem; color: #00667e"
                            >
                              Sobre su situación
                            </p>
                          </div>
                          <div
                            v-if="expanded != ''"
                            class="text-center q-pa-lg text-body1"
                            style="font-size: 20px; line-height: 1.5"
                          >
                            {{ UserReport[expanded].AboutSituation[0] }}
                          </div>

                          <div v-else class="">Not Available</div>
                        </q-card-section>

                        <q-card-section v-if="topic == 'nextSteps'">
                          <div
                            v-if="
                              UserReport[expanded].NextStep1 &&
                              UserReport[expanded].NextStep2 &&
                              UserReport[expanded].NextStep3
                            "
                            class="text-center q-pa-sm"
                            style="line-height: 1.5"
                          >
                            <q-stepper
                              v-model="step"
                              ref="stepper"
                              animated
                              header-nav
                              keep-alive
                              active-color="primary"
                              inactive-color="secondary"
                              infinite
                              swipeable
                              style="font-size: 20px; min-height: 325px"
                            >
                              <q-step
                                :name="1"
                                prefix="1"
                                :title="
                                  UserReport[expanded].NextStep1Title[0]
                                "
                              >
                                <div v-html="
                                  UserReport[expanded].NextStep1[0]"
                                  ></div>
                              </q-step>

                              <q-step
                                :name="2"
                                prefix="2"
                                 :title="
                                  UserReport[expanded].NextStep2Title[0]
                                "
                              >
                                <div v-html="
                                  UserReport[expanded].NextStep2[0]"
                                  ></div>
                              </q-step>

                              <q-step
                                :name="3"
                                prefix="3"
                                :title="
                                  UserReport[expanded].NextStep3Title[0]
                                "
                              >
                                <div v-html="
                                  UserReport[expanded].NextStep3[0]"
                                  ></div>
                              </q-step>

                              <template v-slot:navigation>
                                <q-stepper-navigation>
                                  <q-btn
                                    v-if="language == 'EN'"
                                    @click="
                                      $refs.stepper[
                                        stepperTracker[category]
                                      ].next()
                                    "
                                    color="primary"
                                    :label="'Continue'"
                                  />
                                  <q-btn
                                    v-if="language == 'ES'"
                                    @click="
                                      $refs.stepper[
                                        stepperTracker[category]
                                      ].next()
                                    "
                                    color="primary"
                                    :label="'Continuar'"
                                  />
                                </q-stepper-navigation>
                              </template>
                            </q-stepper>
                          </div>

                          <div class="" v-else>
                            There are no next steps for you.
                          </div>
                        </q-card-section>

                        <q-card-section v-if="topic == 'findHelp'">
                          <div class="q-pa-lg" style="">
                            <div class="row items-start">
                              <div
                                class="col-12 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-xs-12 column"
                              >
                                <div
                                  class="col q-pa-md"
                                  style="min-width: 70%; min-height: 70%"
                                  v-for="resource in legalResources"
                                  :key="resource.id"
                                >
                                  <q-card
                                    flat
                                    class="text-primary text-left resourceCard"
                                    style="
                                      min-height: 325px;
                                      font-size: 20px;
                                      line-height: 1.5;
                                    "
                                  >
                                    <q-card-section class="resource-header">
                                      <div class="text-h5 text-bold">
                                        {{ resource.organizationName }}
                                      </div>
                                      <div class="text-subtitle1">
                                        {{ resource.description }}
                                      </div>
                                    </q-card-section>
                                    <q-separator class="secondary" inset />

                                    <q-card-section>
                                      <div class="column">
                                        <div
                                          class="col"
                                          v-if="resource.officeName"
                                        >
                                          <p>
                                            <!-- <strong>Office: </strong> -->
                                            {{ resource.officeName }}
                                          </p>
                                        </div>
                                        <div
                                          class="col"
                                          v-if="resource.address"
                                        >
                                          <p>
                                            <!-- <strong>Address: </strong> -->
                                            {{ resource.address }}
                                            <br />
                                            {{ resource.city }},
                                            {{ resource.state }}
                                            {{ resource.zip }}
                                          </p>
                                        </div>
                                        <div
                                          class="col"
                                          v-if="resource.phoneNumber"
                                        >
                                          <p>
                                            Direct:
                                            <strong>
                                              {{ resource.phoneNumber }}</strong
                                            >
                                          </p>
                                          <p v-if="resource.tollFreeNumber">
                                            Toll Free Number:
                                            <strong>
                                              {{
                                                resource.tollFreeNumber
                                              }}</strong
                                            >
                                          </p>
                                        </div>
                                        <div
                                          class="col"
                                          v-if="resource.websiteAddress"
                                        >
                                          <p>
                                            <!-- <strong>Website:</strong> -->
                                            <a
                                              class="dont-break-out"
                                              style="color: #00667e"
                                              target="blank"
                                              :href="resource.websiteAddress"
                                              >Website</a
                                            >
                                          </p>
                                        </div>
                                        <div
                                          class="col"
                                          v-if="resource.emailAddress"
                                        >
                                          <p>
                                            <strong>Email:</strong>
                                            {{ resource.email }}
                                          </p>
                                        </div>
                                      </div>
                                    </q-card-section>
                                  </q-card>
                                </div>
                              </div>

                              <div
                                class="col-12 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-xs-12 column"
                              >
                                <div
                                  class="col q-pa-md"
                                  style="min-width: 70%; min-height: 70%"
                                  v-for="resource in localResources"
                                  :key="resource.id"
                                >
                                  <q-card
                                    flat
                                    class="text-primary text-left resourceCard"
                                    style="
                                      min-height: 325px;
                                      font-size: 20px;
                                      line-height: 1.5;
                                    "
                                  >
                                    <q-card-section class="resource-header">
                                      <div class="text-h5 text-bold">
                                        {{ resource.organizationName }}
                                      </div>
                                      <div class="text-subtitle1">
                                        {{ resource.description }}
                                      </div>
                                    </q-card-section>
                                    <q-separator class="secondary" inset />

                                    <q-card-section>
                                      <div class="column">
                                        <div
                                          class="col"
                                          v-if="resource.officeName"
                                        >
                                          <p>
                                            <!-- <strong>Office: </strong> -->
                                            {{ resource.officeName }}
                                          </p>
                                        </div>
                                        <div
                                          class="col"
                                          v-if="resource.address"
                                        >
                                          <p>
                                            <!-- <strong>Address: </strong> -->
                                            {{ resource.address }}
                                            <br />
                                            {{ resource.city }},
                                            {{ resource.state }}
                                            {{ resource.zip }}
                                          </p>
                                        </div>
                                        <div
                                          class="col"
                                          v-if="resource.phoneNumber"
                                        >
                                          <p>
                                            Direct:
                                            <strong>
                                              {{ resource.phoneNumber }}</strong
                                            >
                                          </p>
                                          <p v-if="resource.tollFreeNumber">
                                            Toll Free Number:
                                            <strong>
                                              {{
                                                resource.tollFreeNumber
                                              }}</strong
                                            >
                                          </p>
                                        </div>
                                        <div
                                          class="col"
                                          v-if="resource.websiteAddress"
                                        >
                                          <p>
                                            <!-- <strong>Website:</strong> -->
                                            <a
                                              class="dont-break-out"
                                              style="color: #00667e"
                                              target="blank"
                                              @click="track(resource.topic, resource.websiteAddress)"
                                              :href="resource.websiteAddress"
                                              >Website</a
                                            >
                                          </p>
                                        </div>
                                        <div
                                          class="col"
                                          v-if="resource.emailAddress"
                                        >
                                          <p>
                                            <strong>Email:</strong>
                                            {{ resource.email }}
                                          </p>
                                        </div>
                                      </div>
                                    </q-card-section>
                                  </q-card>
                                </div>
                              </div>
                            </div>
                          </div>
                        </q-card-section>

                        <q-card-section v-if="topic == 'moreInfo'">
                          <div v-if="UserReport[expanded].MoreInfo">
                            <div
                              v-for="moreInfo in UserReport[expanded].MoreInfo"
                              :key="moreInfo"
                              class="text-h6 text-center q-pa-md"
                              style="
                                font-size: 20px;
                                line-height: 1.5;
                                color: #0c667d;
                              "
                            >
                              <div
                                v-html="moreInfo"
                                class="col-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 column"
                              ></div>
                            </div>
                            <br />
                          </div>
                        </q-card-section>
                      </div>
                    </div>
                  </q-card>
                </q-expansion-item>
                <q-separator />
              </q-list>
            </div>
          </div>
        </div>
      </div>

      <!-- *********************************  Start template for mobile report  *************************************** -->
      <div
        v-if="$q.platform.is.mobile"
        class="row items-center justify-center bottomSide"
      >
        <div
          style=""
          v-if="categories.length != 0"
          class="col-12 justify-center"
        >
          <!-- Expansion Items -->
          <div class="q-pa-sm" style="">
            <q-list style>
              <q-expansion-item
                style="width: 100% !important"
                :value="expanded == category"
                @click="
                  () => {
                    expanded = category;
                    $refs.stepper[stepperTracker[category]].goTo(1);
                  }
                "
                class="q-pa-sm"
                v-for="category in categories"
                :key="category"
                :group="category"
                :header-style="{ height: '4rem' }"
                expand-separator
                expand-icon-class="text-white"
                @show="
                  () => {
                    topic = 'about';
                  }
                "
                header-class="bg-cyan-9 text-bold text-center"
              >
                <template v-slot:header>
                  <q-item-section
                    style="font-size: 3vh; color: #fff"
                    class="foundationFont"
                    >{{ category }}</q-item-section
                  >
                </template>
                <q-card v-if="category == 'Other' || category == 'otro'">
                  <!-- Other section in English -->
                  <div
                    v-if="language == 'EN'"
                    class="col-12 row q-pa-md items-center justify-center"
                  >
                    <h5 class="text-bold" style="color: #00667e; width: 90%">
                      Visit these links to find more information on topics
                      related to your answers:
                    </h5>
                    <div class="col-12 q-pa-sm">
                      <q-btn
                        @click="
                          openUrl(
                            'https://azcrimevictimhelp.org/other/dating-violence'
                          )
                        "
                        flat
                        icon-right="keyboard_arrow_right"
                        class="otherBtn"
                        color="primary"
                        size="1.25rem"
                        label="Domestic Violence"
                        no-caps
                        align="left"
                        style="font-weight: bold"
                      />
                    </div>
                    <div class="col-12 q-pa-sm">
                      <q-btn
                        @click="
                          openUrl(
                            'https://azcrimevictimhelp.org/other/sexual-violence'
                          )
                        "
                        flat
                        icon-right="keyboard_arrow_right"
                        class="otherBtn"
                        color="primary"
                        size="1.25rem"
                        label="Sexual Violence"
                        no-caps
                        align="left"
                        style="font-weight: bold"
                      />
                    </div>
                    <div class="col-12 q-pa-sm">
                      <q-btn
                        @click="
                          openUrl(
                            'https://azcrimevictimhelp.org/other/elder-abuse'
                          )
                        "
                        flat
                        icon-right="keyboard_arrow_right"
                        class="otherBtn"
                        color="primary"
                        size="1.25rem"
                        label="Elder Abuse"
                        no-caps
                        align="left"
                        style="font-weight: bold"
                      />
                    </div>
                    <div class="col-12 q-pa-sm">
                      <q-btn
                        @click="
                          openUrl(
                            'https://azcrimevictimhelp.org/other/child-abuse'
                          )
                        "
                        flat
                        icon-right="keyboard_arrow_right"
                        class="otherBtn"
                        color="primary"
                        size="1.25rem"
                        label="Child Abuse"
                        no-caps
                        align="left"
                        style="font-weight: bold"
                      />
                    </div>
                    <div class="col-12 q-pa-sm">
                      <q-btn
                        @click="
                          openUrl(
                            'https://azcrimevictimhelp.org/other/human-trafficking'
                          )
                        "
                        flat
                        icon-right="keyboard_arrow_right"
                        class="otherBtn"
                        color="primary"
                        size="1.25rem"
                        label="Human Trafficking"
                        no-caps
                        align="left"
                        style="font-weight: bold"
                      />
                    </div>
                    <div class="col-12 q-pa-sm">
                      <q-btn
                        @click="
                          openUrl(
                            'https://azcrimevictimhelp.org/other/Stalking'
                          )
                        "
                        flat
                        icon-right="keyboard_arrow_right"
                        class="otherBtn"
                        color="primary"
                        size="1.25rem"
                        label="Stalking"
                        no-caps
                        align="left"
                        style="font-weight: bold"
                      />
                    </div>
                    <div class="col-12 q-pa-sm">
                      <q-btn
                        @click="
                          openUrl(
                            'https://azcrimevictimhelp.org/other/Bullying'
                          )
                        "
                        flat
                        icon-right="keyboard_arrow_right"
                        class="otherBtn"
                        color="primary"
                        size="1.25rem"
                        label="Bullying"
                        no-caps
                        align="left"
                        style="font-weight: bold"
                      />
                    </div>

                    <div class="col-12 q-pa-sm">
                      <q-btn
                        @click="
                          openUrl(
                            'https://azcrimevictimhelp.org/other/tech-crimes'
                          )
                        "
                        flat
                        icon-right="keyboard_arrow_right"
                        class="otherBtn"
                        color="primary"
                        size="1.25rem"
                        label="Tech Crimes"
                        no-caps
                        align="left"
                        style="font-weight: bold"
                      />
                    </div>
                  </div>
                  <!-- Other section in Spanish -->
                  <div
                    v-if="language == 'ES'"
                    class="col-12 row q-pa-md items-center justify-center"
                  >
                    <h5 class="text-bold" style="color: #00667e; width: 90%">
                      Visite estos enlaces para encontrar más información sobre
                      temas relacionados con sus respuestas:
                    </h5>
                    <div class="col-12 q-pa-sm">
                      <q-btn
                        @click="
                          openUrl(
                            'https://azcrimevictimhelp.org/other/dating-violence'
                          )
                        "
                        flat
                        icon-right="keyboard_arrow_right"
                        class="otherBtn"
                        color="primary"
                        size="1.25rem"
                        label="Violencia Doméstica"
                        no-caps
                        align="left"
                        style="font-weight: bold"
                      />
                    </div>
                    <div class="col-12 q-pa-sm">
                      <q-btn
                        @click="
                          openUrl(
                            'https://azcrimevictimhelp.org/other/sexual-violence'
                          )
                        "
                        flat
                        icon-right="keyboard_arrow_right"
                        class="otherBtn"
                        color="primary"
                        size="1.25rem"
                        label="Violencia Sexual"
                        no-caps
                        align="left"
                        style="font-weight: bold"
                      />
                    </div>
                    <div class="col-12 q-pa-sm">
                      <q-btn
                        @click="
                          openUrl(
                            'https://azcrimevictimhelp.org/other/elder-abuse'
                          )
                        "
                        flat
                        icon-right="keyboard_arrow_right"
                        class="otherBtn"
                        color="primary"
                        size="1.25rem"
                        label="Maltrato de Mayores"
                        no-caps
                        align="left"
                        style="font-weight: bold"
                      />
                    </div>
                    <div class="col-12 q-pa-sm">
                      <q-btn
                        @click="
                          openUrl(
                            'https://azcrimevictimhelp.org/other/child-abuse'
                          )
                        "
                        flat
                        icon-right="keyboard_arrow_right"
                        class="otherBtn"
                        color="primary"
                        size="1.25rem"
                        label="Abuso Infantil"
                        no-caps
                        align="left"
                        style="font-weight: bold"
                      />
                    </div>
                    <div class="col-12 q-pa-sm">
                      <q-btn
                        @click="
                          openUrl(
                            'https://azcrimevictimhelp.org/other/human-trafficking'
                          )
                        "
                        flat
                        icon-right="keyboard_arrow_right"
                        class="otherBtn"
                        color="primary"
                        size="1.25rem"
                        label="Trata de Personas"
                        no-caps
                        align="left"
                        style="font-weight: bold"
                      />
                    </div>
                    <div class="col-12 q-pa-sm">
                      <q-btn
                        @click="
                          openUrl(
                            'https://azcrimevictimhelp.org/other/Stalking'
                          )
                        "
                        flat
                        icon-right="keyboard_arrow_right"
                        class="otherBtn"
                        color="primary"
                        size="1.25rem"
                        label="Accecho"
                        no-caps
                        align="left"
                        style="font-weight: bold"
                      />
                    </div>
                    <div class="col-12 q-pa-sm">
                      <q-btn
                        @click="
                          openUrl(
                            'https://azcrimevictimhelp.org/other/Bullying'
                          )
                        "
                        flat
                        icon-right="keyboard_arrow_right"
                        class="otherBtn"
                        color="primary"
                        size="1.25rem"
                        label="Acoso"
                        no-caps
                        align="left"
                        style="font-weight: bold"
                      />
                    </div>

                    <div class="col-12 q-pa-sm">
                      <q-btn
                        @click="
                          openUrl(
                            'https://azcrimevictimhelp.org/other/tech-crimes'
                          )
                        "
                        flat
                        icon-right="keyboard_arrow_right"
                        class="otherBtn"
                        color="primary"
                        size="1.25rem"
                        label="Delitos Tecnológicos"
                        no-caps
                        align="left"
                        style="font-weight: bold"
                      />
                    </div>
                  </div>
                </q-card>

                <q-card v-else flat>
                  <div class="row">
                    <div class="col-12">
                      <div class="row q-pa-none">
                        <div
                          class="col-3 q-px-none"
                          style="background-color: #82b8c4"
                        >
                          <q-btn
                            class="foundationFont full-width content-btnMobile shadow-1"
                            flat
                            v-bind:class="{
                              activeBtnMobile: topic == 'about',
                            }"
                            style="font-size: 1.25rem"
                            color="white"
                            icon="info"
                            @click="makeActive($event, 'about')"
                          />
                        </div>

                        <div
                          class="col-3 q-px-none"
                          style="background-color: #82b8c4"
                        >
                          <q-btn
                            class="foundationFont full-width content-btnMobile shadow-1"
                            flat
                            v-bind:class="{
                              activeBtnMobile: topic == 'nextSteps',
                            }"
                            style="font-size: 1.25rem"
                            color="white"
                            icon="format_list_numbered"
                            @click="makeActive($event, 'nextSteps')"
                          />
                        </div>

                        <div
                          class="col-3 q-px-none"
                          style="background-color: #82b8c4"
                        >
                          <q-btn
                            class="foundationFont full-width content-btnMobile shadow-1"
                            flat
                            v-bind:class="{
                              activeBtnMobile: topic == 'findHelp',
                            }"
                            style="font-size: 1.25rem"
                            color="white"
                            icon="help"
                            @click="makeActive($event, 'findHelp')"
                          />
                        </div>

                        <div
                          class="col-3 q-px-none"
                          style="background-color: #82b8c4"
                        >
                          <q-btn
                            class="foundationFont full-width content-btnMobile shadow-1"
                            flat
                            v-bind:class="{
                              activeBtnMobile: topic == 'moreInfo',
                            }"
                            style="font-size: 1.25rem"
                            color="white"
                            icon="screen_share"
                            @click="makeActive($event, 'moreInfo')"
                          />
                        </div>
                      </div>
                    </div>
                    <div class="col-12 content-areaMobile" color="accent">
                      <!-- About your situation -->
                      <q-card-section v-if="topic == 'about'">
                        <div class="flex flex-center">
                          <!-- English -->
                          <p
                            v-if="language == 'EN'"
                            class="text-bold q-pt-lg"
                            style="font-size: 1.25rem; color: #00667e"
                          >
                            About Your Situation
                          </p>
                          <!-- Spanish -->
                          <p
                            v-if="language == 'ES'"
                            class="text-bold q-pt-lg"
                            style="font-size: 1.25rem; color: #00667e"
                          >
                            Sobre su situación
                          </p>
                        </div>
                        <div
                          v-if="expanded != ''"
                          class="text-center q-pa-lg text-body1"
                          style="font-size: 20px; line-height: 1.5"
                        >
                          {{ UserReport[expanded].AboutSituation[0] }}
                        </div>

                        <div v-else class="">Not Available</div>
                      </q-card-section>

                      <q-card-section v-if="topic == 'nextSteps'">
                        <div
                          v-if="
                            UserReport[expanded].NextStep1 &&
                            UserReport[expanded].NextStep2 &&
                            UserReport[expanded].NextStep3
                          "
                          class="text-center q-pa-md"
                          style="font-size: 16px; line-height: 1.5"
                        >
                          <q-stepper
                            v-model="step"
                            ref="stepper"
                            animated
                            header-nav
                            keep-alive
                            active-color="primary"
                            inactive-color="secondary"
                            infinite
                            swipeable
                            style="font-size: 20px"
                          >
                            <q-step
                              :name="1"
                              prefix="1"
                              :title="
                                UserReport[expanded].NextStep1Title[0]
                              "
                            >
                              <div v-html="
                                UserReport[expanded].NextStep1[0]"
                              ></div>
                            </q-step>

                            <q-step
                              :name="2"
                              prefix="2"
                              :title="
                                UserReport[expanded].NextStep2Title[0]
                              "
                            >
                              <div v-html="
                                UserReport[expanded].NextStep2[0]"
                              ></div>
                            </q-step>

                            <q-step
                              :name="3"
                              prefix="3"
                              :title="
                                UserReport[expanded].NextStep3Title[0]
                              "
                            >
                              <div v-html="
                                UserReport[expanded].NextStep3[0]"
                              ></div>
                            </q-step>

                            <template v-slot:navigation>
                              <q-stepper-navigation>
                                <q-btn
                                  v-if="language == 'EN'"
                                  @click="
                                    $refs.stepper[
                                      stepperTracker[category]
                                    ].next()
                                  "
                                  color="primary"
                                  :label="'Continue'"
                                />
                                <q-btn
                                  v-if="language == 'ES'"
                                  @click="
                                    $refs.stepper[
                                      stepperTracker[category]
                                    ].next()
                                  "
                                  color="primary"
                                  :label="'Continuar'"
                                />
                              </q-stepper-navigation>
                            </template>
                          </q-stepper>
                        </div>

                        <div class="" v-else>
                          There are no next steps for you.
                        </div>
                      </q-card-section>

                      <q-card-section v-if="topic == 'findHelp'">
                        <div class="q-pa-sm" style="">
                          <div class="row items-start">
                            <div
                              class="col-12 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-xs-12 column"
                            >
                              <div
                                class="col-12 q-pa-xs"
                                style="min-width: 70%; min-height: 70%"
                                v-for="resource in legalResources"
                                :key="resource.id"
                              >
                                <q-card
                                  flat
                                  class="text-primary text-left resourceCard"
                                >
                                  <q-card-section class="resource-header">
                                    <div class="text-h5 text-bold">
                                      {{ resource.organizationName }}
                                    </div>
                                    <div class="text-subtitle1">
                                      {{ resource.description }}
                                    </div>
                                  </q-card-section>
                                  <q-separator class="secondary" inset />

                                  <q-card-section>
                                    <div class="column">
                                      <div
                                        class="col"
                                        v-if="resource.officeName"
                                      >
                                        <p>
                                          {{ resource.officeName }}
                                        </p>
                                      </div>
                                      <div class="col" v-if="resource.address">
                                        <p>
                                          {{ resource.address }}
                                          <br />
                                          {{ resource.city }},
                                          {{ resource.state }}
                                          {{ resource.zip }}
                                        </p>
                                      </div>
                                      <div
                                        class="col"
                                        v-if="resource.phoneNumber"
                                      >
                                        <p>
                                          Direct:
                                          <strong>{{
                                            resource.phoneNumber
                                          }}</strong>
                                        </p>
                                        <p v-if="resource.tollFreeNumber">
                                          Toll Free Number:
                                          <strong>{{
                                            resource.tollFreeNumber
                                          }}</strong>
                                        </p>
                                      </div>
                                      <div
                                        class="col"
                                        v-if="resource.websiteAddress"
                                      >
                                          <a
                                            class="dont-break-out"
                                            style="color: #00667e"
                                            target="blank"
                                            @click="track(resource.topic, resource.websiteAddress)"
                                            :href="resource.websiteAddress"
                                            >Website</a
                                          >
                                      </div>
                                      <div
                                        class="col"
                                        v-if="resource.emailAddress"
                                      >
                                        <p>
                                          <strong>Email:</strong>
                                          {{ resource.email }}
                                        </p>
                                      </div>
                                    </div>
                                  </q-card-section>
                                </q-card>
                              </div>
                            </div>

                            <div
                              class="col-12 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-xs-12 column"
                            >
                              <div
                                class="col-12 q-pa-sm"
                                style="min-width: 70%; min-height: 70%"
                                v-for="resource in localResources"
                                :key="resource.id"
                              >
                                <q-card
                                  flat
                                  class="text-primary text-left resourceCard"
                                >
                                  <q-card-section class="resource-header">
                                    <div class="text-h5 text-bold">
                                      {{ resource.organizationName }}
                                    </div>
                                    <div class="text-subtitle1">
                                      {{ resource.description }}
                                    </div>
                                  </q-card-section>
                                  <q-separator class="secondary" inset />

                                  <q-card-section>
                                    <div class="column">
                                      <div
                                        class="col"
                                        v-if="resource.officeName"
                                      >
                                        <p>
                                          {{ resource.officeName }}
                                        </p>
                                      </div>
                                      <div class="col" v-if="resource.address">
                                        <p>
                                          {{ resource.address }}
                                          <br />
                                          {{ resource.city }},
                                          {{ resource.state }}
                                          {{ resource.zip }}
                                        </p>
                                      </div>
                                      <div
                                        class="col"
                                        v-if="resource.phoneNumber"
                                      >
                                        <p>
                                          Direct:
                                          <strong>{{
                                            resource.phoneNumber
                                          }}</strong>
                                        </p>
                                        <p v-if="resource.tollFreeNumber">
                                          Toll Free Number:
                                          <strong>{{
                                            resource.tollFreeNumber
                                          }}</strong>
                                        </p>
                                      </div>
                                      <div
                                        class="col"
                                        v-if="resource.websiteAddress"
                                      >
                                        <p>
                                          <a
                                            class="dont-break-out"
                                            style="color: #00667e"
                                            target="blank"
                                            :href="resource.websiteAddress"
                                            >Website</a
                                          >
                                        </p>
                                      </div>
                                    </div>
                                  </q-card-section>
                                </q-card>
                              </div>
                            </div>
                          </div>
                        </div>
                      </q-card-section>

                      <q-card-section v-if="topic == 'moreInfo'">
                        <div
                          v-if="UserReport[expanded].MoreInfo"
                          class="col-10 col-xl-10 col-lg-10 col-md-10 col-sm-12 col-xs-12 column dont-break-out"
                        >
                          <div
                            v-for="moreInfo in UserReport[expanded].MoreInfo"
                            :key="moreInfo"
                            class="text-h6 text-center q-pa-lg"
                            style="
                              font-size: 16px;
                              line-height: 1.5;
                              color: #0c667d;
                              width: 90%;
                            "
                          >
                            <div v-html="moreInfo"></div>
                          </div>
                          <br />
                        </div>
                      </q-card-section>
                    </div>
                  </div>
                </q-card>
              </q-expansion-item>
              <q-separator />
            </q-list>
          </div>
        </div>
      </div>

      <q-dialog v-model="restart" persistent>
        <q-card>
          <q-card-actions align="right">
            <q-btn
              class
              flat
              label="X"
              size="1.25rem"
              color="primary"
              v-close-popup
              @click="startover = false"
            />
          </q-card-actions>
          <q-card-section class="row items-center" style="margin-top: -30px">
            <div
              class="q-ma-none q-pa-none text-center"
              style="color: #00667e; margin: auto"
            >
              <h5 v-if="language == 'EN'" class="text-bold" style="height: 0px">
                Are you sure?
              </h5>
              <h5 v-if="language == 'ES'" class="text-bold" style="height: 0px">
                ¿Estas seguro?
              </h5>
            </div>
            <div class="q-pa-none q-ma-sm text-center">
              <h5
                v-if="language == 'EN'"
                style="color: #00667e; margin-block-start: inherit"
              >
                Upon restarting all of your current answers will be deleted. Are
                you sure you want to restart?
              </h5>
              <h5
                v-if="language == 'ES'"
                style="color: #00667e; margin-block-start: inherit"
              >
                Al reiniciar, se eliminarán todas sus respuestas actuales.
                ¿Estás seguro de que quieres reiniciar?
              </h5>
            </div>
          </q-card-section>
          <q-separator color="secondary" inlet />
          <q-card-section class="text-center text">
            <q-btn
              v-if="language == 'EN'"
              flat
              label="Restart"
              color="primary"
              size="1.25rem"
              @click="goBack()"
              style="margin: auto"
            ></q-btn>
            <q-btn
              v-if="language == 'ES'"
              flat
              label="Reiniciar"
              color="primary"
              size="1.25rem"
              @click="goBack()"
              style="margin: auto"
            ></q-btn>
          </q-card-section>
        </q-card>
      </q-dialog>
    </div>
  </div>
</template>

<style></style>
<!-- Global site tag (gtag.js) - Google Analytics -->
<!--<script async src="https://www.googletagmanager.com/gtag/js?id=UA-9894633-23"></script>
<script>
  window.dataLayer = window.dataLayer || [];
  function gtag(){dataLayer.push(arguments);}
  gtag('js', new Date());

  gtag('config', 'UA-9894633-23');
</script> -->

<script>
import { mapGetters } from "vuex";
import _ from "lodash";
import axios from "axios";
import * as humanizeList from "humanize-list";
import "../register-files";
//import ua from 'universal-analytics'

//const visitor = ua('UA-9894633-23');

//const BASE_URL = process.env.VUE_APP_BASE_URL;
axios.defaults.baseURL = process.env.VUE_APP_BASE_URL;

export default {
  name: "PageReport",
  data() {
    return {
      expanded: "",
      stepperTracker: {},
      step: 1,
      startover: false,
      restart: false,
      fileURL: "",
      loading: false,
      category_text: "",
      topic: "about",
      icons: {
        Safety: "local_hospital",
        Finance: "monetization_on",
        Housing: "domain",
        Family: "child_friendly",
        VictimRights: "security",
      },
    };
  },
  created() {
    this.category_text = humanizeList(this.categories, { oxfordComma: true });
  },
  mounted() {
    var that = this;
    var index = 0;
    this.categories.map((category) => {
      that.stepperTracker[category] = index++;
    });

    // axios.post("http://localhost:3000/api/getUserData", {
      axios.post(`/getUserData`, {
        userReport: this.UserReport,
        categories: this.categories,
        byCounty: this.byCounty,
        lang: this.language
      })
      .then(() => {
          // axios.get("http://localhost:3000/api/generatePDF", {
        axios.get(`/generatePDF`, {
            responseType: "blob",
          })

          .then((response) => {
            this.fileURL = URL.createObjectURL(response.data);
          });
      });
  },
  methods: {
    track(eventLabel, eventValue){
      this.$gtag.event('button_click', {
        'event_label': eventLabel,
        'value': eventValue,
      })
    },
    goBack() {
      window.location = window.location.origin;
    },
    exit() {
      window.location = "http://www.google.com";
    },
    goToFindHelp() {
      window.location =
        "https://azcrimevictimhelp.org.test.azflse.org/find-help";
    },
    goToLegalInfo() {
      window.location =
        "https://azcrimevictimhelp.org.test.azflse.org/legal-needs";
    },
    goToTriage() {
      this.$router.push("triage");
    },
    openUrl(url) {
      window.open(url, "_blank");
    },
    makeActive(event, topic) {
      this.topic = topic;
    },
    openPDF() {
      window.open(this.fileURL, "_blank");
    },
  },
  computed: {
    ...mapGetters({
      UserReport: "getUserReport",
      Resources: "getResources",
      county: "getCounty",
      language: "getLanguage",
    }),
    current_about() {
      return this.UserReport[this.slide].about.split("<br>");
    },

    current_nextSteps() {
      return this.UserReport[this.slide].nextSteps;
    },

    byCounty() {
      var that = this;
      return this.Resources.filter(function (resource) {
        if (resource.countiesServed) {
          if (resource.countiesServed.trim() == "ALL") return resource;
          if (resource.countiesServed.trim() == that.county) return resource;
          else if (
            resource.countiesServed
              .split(",")
              .map((county) => {
                return county.trim();
              })
              .includes(that.county)
          )
            return resource;
        }
      });
    },

    legalResources() {
      return this.byCounty.filter(function (resource) {
        if (resource.serviceProvided == "Legal") return resource;
      });
    },

    localResources() {
      var that = this;
      return this.byCounty.filter(function (resource) {
        if (resource.topic) {
          if (resource.topic == that.expanded) return resource;
          if (
            resource.topic
              .split(",")
              .map((county) => {
                return county.trim();
              })
              .includes(that.expanded)
          )
            return resource;
        }
      });
    },
    categories() {
      return _.map(this.UserReport, (value, key) => {
        // if (typeof value.FindHelp != "undefined")
        //   if (value.FindHelp.length != 0) return key;
        // if (typeof value.MoreInfo == "undefined")
        //   if (value.MoreInfo.length != 0) return key;
        // if (typeof value.NextSteps == "undefined")
        //   if (value.NextSteps.length != 0) return key;
        return key;
      }).filter((el) => el != null && el != "General");
    },
  },
};
</script>

<style scoped>
.foundationFont {
  font-family: "Open Sans", sans-serif;
}

.headerText {
  font-size: 1.5vmax;
  color: #00667d;
  font-family: "Open Sans", sans-serif;
  line-height: 1.5;
}

.headerCategories {
  font-size: 2vmax;
  color: #00667e;
  font-family: "Open Sans", sans-serif;
  line-height: 1.55;
}

.topSide {
  background-color: #fff;
  height: 100%;
  width: 100%;
}

.topSideNoCats {
  width: 100%;
  height: 50vh;
}

.bottomSide {
  height: 100%;
  width: 100%;
  background-color: #82b8c4;
  background: border-box;
}

.content-area {
  border: 1px transparent solid;
  border-radius: 25px;
  width: 73%;
  height: 28rem;
}

.content-areaMobile {
  border: 1px transparent solid;
  border-radius: 25px;
}

.accent {
  color: #603f85;
}

.dont-break-out {
  overflow-wrap: break-word;
  word-wrap: break-word;
}

.content-btn {
  border: 1px transparent solid;
  border-radius: 25px;
  min-width: 100%;
  height: 100%;
}

.content-btnMobile {
  height: 100%;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.activeBtn {
  margin-left: 60px;
  background-color: #603f85 !important;
}

.activeBtnMobile {
  background-color: #ffffff !important;
  color: #00667e !important;
}

.resourceCard {
  width: 100%;
  min-height: 325px;
}

.otherBtn {
  min-width: 80%;
  min-height: 70%;
}

@media screen and (max-width: 768px) {
  .downloadPosition {
    text-align: center !important;
    width: 100% !important;
  }

  .downloadBtn {
    font-size: 1rem !important;
    border-radius: 10px;
    font-family: "Open Sans", sans-serif;
  }
}

@media screen and (min-width: 1920px) {
  .headerText {
    font-size: 1.5vmin;
    color: #00667e;
    font-family: "Open Sans", sans-serif;
    line-height: 1.5;
  }

  .headerCategories {
    font-size: 2vmin;
    color: #00667e;
    font-family: "Open Sans", sans-serif;
    line-height: 1.55;
  }
}


@media screen and (min-width: 1440px) {
  .resource-header {
    height: 80px;
  }
}

@media screen and (min-width: 1140px) {
  .resource-header {
    height: 80px;
  }
} 


</style>
