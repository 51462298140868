var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    this.categories.length == 0 && _vm.language == "EN"
      ? _c(
          "div",
          { staticClass: "topSideNoCats" },
          [
            _c("div", { staticClass: "row justify-center q-pa-md" }, [
              _c(
                "div",
                { staticClass: "col-12" },
                [
                  _c("q-btn", {
                    staticStyle: { float: "left" },
                    attrs: {
                      flat: "",
                      icon: "arrow_back_ios",
                      label: "RESTART",
                      color: "secondary",
                    },
                    on: {
                      click: function ($event) {
                        _vm.startover = true
                      },
                    },
                  }),
                ],
                1
              ),
              _c(
                "div",
                {
                  staticClass:
                    "col-12 text-center q-py-lg col-lg-8 col-md-8 col-sm-12 col-xs-12",
                },
                [
                  _c(
                    "q-banner",
                    {
                      staticClass: "foundationFont q-pa-lg",
                      staticStyle: {
                        "background-color": "transparent",
                        color: "#fff",
                      },
                    },
                    [
                      _c("span", { staticClass: "headerText" }, [
                        _vm._v(
                          "\n            The check up tool was unable to identify a legal area of need.\n            Please explore the website to find information and resources.\n            "
                        ),
                        _c(
                          "div",
                          {
                            staticClass: "text-bold q-pt-xl",
                            staticStyle: {
                              "font-size": "4vh",
                              "line-height": "1.25",
                            },
                          },
                          [
                            _c("q-btn", {
                              staticClass: "q-ma-md",
                              attrs: {
                                label: "Visit Legal Needs Page",
                                color: "primary",
                                size: "1.25rem",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.goToLegalInfo()
                                },
                              },
                            }),
                            _c("q-btn", {
                              staticClass: "q-ma-md",
                              attrs: {
                                label: "Visit Find Help Page",
                                color: "primary",
                                size: "1.25rem",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.goToFindHelp()
                                },
                              },
                            }),
                          ],
                          1
                        ),
                        _c("br"),
                      ]),
                    ]
                  ),
                ],
                1
              ),
            ]),
            _c(
              "q-dialog",
              {
                attrs: { persistent: "" },
                model: {
                  value: _vm.startover,
                  callback: function ($$v) {
                    _vm.startover = $$v
                  },
                  expression: "startover",
                },
              },
              [
                _c(
                  "q-card",
                  [
                    _c(
                      "q-card-actions",
                      { attrs: { align: "right" } },
                      [
                        _c("q-btn", {
                          directives: [
                            { name: "close-popup", rawName: "v-close-popup" },
                          ],
                          attrs: {
                            flat: "",
                            label: "X",
                            size: "1.25rem",
                            color: "primary",
                          },
                          on: {
                            click: function ($event) {
                              _vm.startover = false
                            },
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "q-card-section",
                      {
                        staticClass: "row items-center",
                        staticStyle: { "margin-top": "-30px" },
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "q-ma-none q-pa-none text-center",
                            staticStyle: { color: "#00667e", margin: "auto" },
                          },
                          [
                            _c(
                              "h5",
                              {
                                staticClass: "text-bold",
                                staticStyle: { height: "0px" },
                              },
                              [_vm._v("Are you sure?")]
                            ),
                          ]
                        ),
                        _c(
                          "div",
                          {
                            staticClass:
                              "q-pa-none q-ma-sm text-center reportModal",
                          },
                          [
                            _c(
                              "h5",
                              {
                                staticStyle: {
                                  color: "#00667e",
                                  "margin-block-start": "inherit",
                                },
                              },
                              [
                                _vm._v(
                                  "\n              Upon restarting all of your current answers will be deleted. Are\n              you sure you want to restart?\n            "
                                ),
                              ]
                            ),
                          ]
                        ),
                      ]
                    ),
                    _c("q-separator", {
                      attrs: { color: "seconday", inlet: "" },
                    }),
                    _c(
                      "q-card-section",
                      { staticClass: "text-center" },
                      [
                        _c("q-btn", {
                          staticStyle: { margin: "auto" },
                          attrs: {
                            flat: "",
                            label: "Restart",
                            color: "primary",
                            size: "1.25rem",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.goBack()
                            },
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        )
      : this.categories.length == 0 && _vm.language == "ES"
      ? _c(
          "div",
          { staticClass: "topSideNoCats" },
          [
            _c("div", { staticClass: "row justify-center q-pa-md" }, [
              _c(
                "div",
                { staticClass: "col-12" },
                [
                  _c("q-btn", {
                    staticStyle: { float: "left" },
                    attrs: {
                      flat: "",
                      icon: "arrow_back_ios",
                      label: "REINICIAR",
                      color: "secondary",
                    },
                    on: {
                      click: function ($event) {
                        _vm.startover = true
                      },
                    },
                  }),
                ],
                1
              ),
              _c(
                "div",
                {
                  staticClass:
                    "col-12 text-center q-py-lg col-lg-8 col-md-8 col-sm-12 col-xs-12",
                },
                [
                  _c(
                    "q-banner",
                    {
                      staticClass: "foundationFont q-pa-lg",
                      staticStyle: {
                        "background-color": "transparent",
                        color: "#fff",
                      },
                    },
                    [
                      _c("span", { staticClass: "headerText" }, [
                        _vm._v(
                          "\n            La herramienta de verificación no pudo identificar un área legal\n            de necesidad. Explore el sitio web para encontrar información y\n            recursos.\n            "
                        ),
                        _c(
                          "div",
                          {
                            staticClass: "text-bold q-pt-xl",
                            staticStyle: {
                              "font-size": "4vh",
                              "line-height": "1.25",
                            },
                          },
                          [
                            _c("q-btn", {
                              staticClass: "q-ma-md",
                              attrs: {
                                label:
                                  "Visite la página de necesidades legales",
                                color: "primary",
                                size: "1.25rem",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.goToLegalInfo()
                                },
                              },
                            }),
                            _c("q-btn", {
                              staticClass: "q-ma-md",
                              attrs: {
                                label: "Visite la página buscar ayuda",
                                color: "primary",
                                size: "1.25rem",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.goToFindHelp()
                                },
                              },
                            }),
                          ],
                          1
                        ),
                        _c("br"),
                      ]),
                    ]
                  ),
                ],
                1
              ),
            ]),
            _c(
              "q-dialog",
              {
                attrs: { persistent: "" },
                model: {
                  value: _vm.startover,
                  callback: function ($$v) {
                    _vm.startover = $$v
                  },
                  expression: "startover",
                },
              },
              [
                _c(
                  "q-card",
                  [
                    _c(
                      "q-card-actions",
                      { attrs: { align: "right" } },
                      [
                        _c("q-btn", {
                          directives: [
                            { name: "close-popup", rawName: "v-close-popup" },
                          ],
                          attrs: {
                            flat: "",
                            label: "X",
                            size: "1.5rem",
                            color: "primary",
                          },
                          on: {
                            click: function ($event) {
                              _vm.startover = false
                            },
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "q-card-section",
                      {
                        staticClass: "row items-center",
                        staticStyle: { "margin-top": "-30px" },
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "q-ma-none q-pa-none text-center",
                            staticStyle: { color: "#00667e", margin: "auto" },
                          },
                          [
                            _c(
                              "h5",
                              {
                                staticClass: "text-bold",
                                staticStyle: { height: "0px" },
                              },
                              [_vm._v("¿Estas seguro?")]
                            ),
                          ]
                        ),
                        _c(
                          "div",
                          {
                            staticClass:
                              "q-pa-none q-ma-sm text-center reportModal",
                          },
                          [
                            _c(
                              "h5",
                              {
                                staticStyle: {
                                  color: "#00667e",
                                  "margin-block-start": "inherit",
                                },
                              },
                              [
                                _vm._v(
                                  "\n              Al reiniciar, se eliminarán todas sus respuestas actuales.\n              ¿Estás seguro de que quieres reiniciar?\n            "
                                ),
                              ]
                            ),
                          ]
                        ),
                      ]
                    ),
                    _c("q-separator", {
                      attrs: { color: "secondary", inlet: "" },
                    }),
                    _c(
                      "q-card-section",
                      { staticClass: "text-center" },
                      [
                        _c("q-btn", {
                          staticStyle: { margin: "auto" },
                          attrs: {
                            flat: "",
                            label: "Reiniciar",
                            color: "primary",
                            size: "1.25rem",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.goBack()
                            },
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        )
      : _c(
          "div",
          {},
          [
            _c("div", { staticClass: "topSide" }, [
              _vm.language == "EN"
                ? _c(
                    "div",
                    { staticClass: "row items-center justify-between" },
                    [
                      _c(
                        "div",
                        { staticClass: "col-4 q-pa-xs" },
                        [
                          _c("q-btn", {
                            attrs: {
                              color: "secondary",
                              flat: "",
                              icon: "arrow_back_ios",
                              label: "BACK",
                            },
                            on: {
                              click: function ($event) {
                                _vm.restart = true
                              },
                            },
                          }),
                        ],
                        1
                      ),
                      _c("div", { staticClass: "col-4" }),
                      _c(
                        "div",
                        { staticClass: "col-4 q-pa-xs" },
                        [
                          _c("q-btn", {
                            staticStyle: { float: "right" },
                            attrs: {
                              color: "secondary",
                              flat: "",
                              label: "Exit",
                              "icon-right": "close",
                            },
                            on: { click: _vm.exit },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        {
                          staticClass:
                            "col-lg-9 col-md-9 col-sm-12 col-xs-12 q-py-xl q-my-xl",
                          staticStyle: { margin: "auto" },
                        },
                        [
                          _c(
                            "q-banner",
                            { staticStyle: { "text-align": "center" } },
                            [
                              _c(
                                "div",
                                { staticClass: "foundationFont headerText" },
                                [
                                  _vm._v(
                                    "\n              Based on your answers, a report on the following topics has been\n              prepared:\n              "
                                  ),
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "text-bold text-h4 q-pt-md headerCategories",
                                      staticStyle: { margin: "auto" },
                                    },
                                    [
                                      _vm._v(
                                        "\n                " +
                                          _vm._s(_vm.category_text) +
                                          "\n              "
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "div",
                                    { staticClass: "q-pa-sm headerText" },
                                    [
                                      _vm._v(
                                        "\n                Click through each section to find information and resources.\n                The information below is for general legal information\n                purposes only and should not be considered legal advice.\n              "
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                              _vm.loading
                                ? _c(
                                    "div",
                                    {},
                                    [
                                      _c("q-spinner-ios", {
                                        attrs: {
                                          color: "primary",
                                          size: "4vh",
                                        },
                                      }),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                            ]
                          ),
                        ],
                        1
                      ),
                    ]
                  )
                : _vm._e(),
              _vm.language == "ES"
                ? _c(
                    "div",
                    { staticClass: "row items-center justify-between" },
                    [
                      _c(
                        "div",
                        { staticClass: "col-6 q-pa-xs" },
                        [
                          _c("q-btn", {
                            attrs: {
                              color: "secondary",
                              flat: "",
                              icon: "arrow_back_ios",
                              label: "REINICIAR",
                            },
                            on: {
                              click: function ($event) {
                                _vm.restart = true
                              },
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "col-6 q-pa-xs" },
                        [
                          _c("q-btn", {
                            staticStyle: { float: "right" },
                            attrs: {
                              color: "secondary",
                              flat: "",
                              label: "SALIDA",
                              "icon-right": "close",
                            },
                            on: { click: _vm.exit },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        {
                          staticClass:
                            "col-12 text-center col-lg-9 col-md-9 col-sm-12 col-xs-12",
                          staticStyle: { margin: "auto" },
                        },
                        [
                          _c(
                            "q-banner",
                            { staticClass: "foundationFont", staticStyle: {} },
                            [
                              _c("div", { staticClass: "headerText" }, [
                                _vm._v(
                                  "\n              Sobre la base de sus respuestas, se ha elaborado un informe\n              sobre los siguientes temas:\n              "
                                ),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "text-bold text-h4 q-pt-md headerCategories",
                                    staticStyle: { margin: "auto" },
                                  },
                                  [
                                    _vm._v(
                                      "\n                " +
                                        _vm._s(_vm.category_text) +
                                        "\n              "
                                    ),
                                  ]
                                ),
                                _c("br"),
                                _c(
                                  "div",
                                  { staticClass: "q-pa-sm headerText" },
                                  [
                                    _c("p", [
                                      _vm._v(
                                        "\n                  Haga clic en cada sección para encontrar información y\n                  recursos. La siguiente información es solo para fines de\n                  información legal general y no debe considerarse\n                  asesoramiento legal.\n                "
                                      ),
                                    ]),
                                  ]
                                ),
                              ]),
                              _vm.loading
                                ? _c(
                                    "div",
                                    {},
                                    [
                                      _c("q-spinner-ios", {
                                        attrs: {
                                          color: "primary",
                                          size: "5vh",
                                        },
                                      }),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                            ]
                          ),
                        ],
                        1
                      ),
                    ]
                  )
                : _vm._e(),
              _c(
                "div",
                {
                  staticClass: "col-12 q-my-lg downloadPosition",
                  staticStyle: { "text-align": "end", width: "89.25%" },
                },
                [
                  _vm.language == "EN"
                    ? _c("q-btn", {
                        staticClass: "downloadBtn",
                        class: {},
                        staticStyle: { "font-size": "1.25rem" },
                        attrs: {
                          disabled: !_vm.fileURL.includes("blob"),
                          "no-caps": "",
                          color: "primary",
                          icon: "get_app",
                          label: "Download as PDF",
                        },
                        on: {
                          click: function ($event) {
                            _vm.openPDF()
                            _vm.track("Open PDF", "EN")
                          },
                        },
                      })
                    : _vm._e(),
                  _vm.language == "ES"
                    ? _c("q-btn", {
                        staticClass: "downloadBtn",
                        class: {},
                        staticStyle: { "font-size": "1.25rem" },
                        attrs: {
                          disabled: !_vm.fileURL.includes("blob"),
                          "no-caps": "",
                          color: "primary",
                          icon: "get_app",
                          label: "Descarga Este Informe",
                        },
                        on: {
                          click: function ($event) {
                            _vm.openPDF()
                            _vm.track("Open PDF", "ES")
                          },
                        },
                      })
                    : _vm._e(),
                ],
                1
              ),
            ]),
            _vm.$q.platform.is.desktop
              ? _c("div", { staticClass: "row items-center justify-center" }, [
                  _c("div", { staticClass: "bottomSide" }, [
                    _vm.categories.length != 0
                      ? _c(
                          "div",
                          {
                            staticClass: "justify-center items-center",
                            staticStyle: {
                              "max-width": "80%",
                              "max-height": "100%",
                              "margin-left": "auto",
                              "margin-right": "auto",
                            },
                          },
                          [
                            _c(
                              "div",
                              { staticClass: "q-pa-sm text-center" },
                              [
                                _c(
                                  "q-list",
                                  {},
                                  [
                                    _vm._l(_vm.categories, function (category) {
                                      return _c(
                                        "q-expansion-item",
                                        {
                                          key: category,
                                          staticClass: "q-pa-sm",
                                          staticStyle: {
                                            "max-width": "100%",
                                            "max-height": "100%",
                                          },
                                          attrs: {
                                            value: _vm.expanded == category,
                                            group: category,
                                            "header-style": { height: "4rem" },
                                            "expand-separator": "",
                                            "expand-icon-class": "text-white",
                                            "header-class":
                                              "bg-primary text-h4 text-center text-bold",
                                          },
                                          on: {
                                            click: () => {
                                              _vm.expanded = category
                                              _vm.$refs.stepper[
                                                _vm.stepperTracker[category]
                                              ].goTo(1)
                                            },
                                            show: () => {
                                              _vm.topic = "about"
                                            },
                                          },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "header",
                                                fn: function () {
                                                  return [
                                                    _c(
                                                      "q-item-section",
                                                      {
                                                        staticClass:
                                                          "foundationFont",
                                                        staticStyle: {
                                                          "font-size": "3vh",
                                                          color: "#fff",
                                                        },
                                                      },
                                                      [_vm._v(_vm._s(category))]
                                                    ),
                                                  ]
                                                },
                                                proxy: true,
                                              },
                                            ],
                                            null,
                                            true
                                          ),
                                        },
                                        [
                                          category == "Other" ||
                                          category == "Otro"
                                            ? _c("q-card", [
                                                _vm.language == "EN"
                                                  ? _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "col-12 row q-pa-md items-center justify-center",
                                                      },
                                                      [
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "col-6 text-center",
                                                          },
                                                          [
                                                            _c(
                                                              "h5",
                                                              {
                                                                staticClass:
                                                                  "text-bold",
                                                                staticStyle: {
                                                                  color:
                                                                    "#00667e",
                                                                  width: "60%",
                                                                  margin:
                                                                    "auto",
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "\n                        Visit these links to find more information on topics\n                        related to your answers:\n                      "
                                                                ),
                                                              ]
                                                            ),
                                                          ]
                                                        ),
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "col-6",
                                                          },
                                                          [
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "col-4 q-pa-sm",
                                                              },
                                                              [
                                                                _c("q-btn", {
                                                                  staticClass:
                                                                    "otherBtn",
                                                                  staticStyle: {
                                                                    "font-weight":
                                                                      "bold",
                                                                  },
                                                                  attrs: {
                                                                    flat: "",
                                                                    "icon-right":
                                                                      "keyboard_arrow_right",
                                                                    color:
                                                                      "primary",
                                                                    size: "1.5rem",
                                                                    label:
                                                                      "Domestic Violence",
                                                                    "no-caps":
                                                                      "",
                                                                    align:
                                                                      "left",
                                                                  },
                                                                  on: {
                                                                    click:
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        return _vm.openUrl(
                                                                          "https://azcrimevictimhelp.org/other/dating-violence"
                                                                        )
                                                                      },
                                                                  },
                                                                }),
                                                              ],
                                                              1
                                                            ),
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "col-4 q-pa-sm",
                                                              },
                                                              [
                                                                _c("q-btn", {
                                                                  staticClass:
                                                                    "otherBtn",
                                                                  staticStyle: {
                                                                    "font-weight":
                                                                      "bold",
                                                                  },
                                                                  attrs: {
                                                                    flat: "",
                                                                    "icon-right":
                                                                      "keyboard_arrow_right",
                                                                    color:
                                                                      "primary",
                                                                    size: "1.5rem",
                                                                    label:
                                                                      "Sexual Violence",
                                                                    "no-caps":
                                                                      "",
                                                                    align:
                                                                      "left",
                                                                  },
                                                                  on: {
                                                                    click:
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        return _vm.openUrl(
                                                                          "https://azcrimevictimhelp.org/other/sexual-violence"
                                                                        )
                                                                      },
                                                                  },
                                                                }),
                                                              ],
                                                              1
                                                            ),
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "col-4 q-pa-sm",
                                                              },
                                                              [
                                                                _c("q-btn", {
                                                                  staticClass:
                                                                    "otherBtn",
                                                                  staticStyle: {
                                                                    "font-weight":
                                                                      "bold",
                                                                  },
                                                                  attrs: {
                                                                    flat: "",
                                                                    "icon-right":
                                                                      "keyboard_arrow_right",
                                                                    color:
                                                                      "primary",
                                                                    size: "1.5rem",
                                                                    label:
                                                                      "Elder Abuse",
                                                                    "no-caps":
                                                                      "",
                                                                    align:
                                                                      "left",
                                                                  },
                                                                  on: {
                                                                    click:
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        return _vm.openUrl(
                                                                          "https://azcrimevictimhelp.org/other/elder-abuse"
                                                                        )
                                                                      },
                                                                  },
                                                                }),
                                                              ],
                                                              1
                                                            ),
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "col-4 q-pa-sm",
                                                              },
                                                              [
                                                                _c("q-btn", {
                                                                  staticClass:
                                                                    "otherBtn",
                                                                  staticStyle: {
                                                                    "font-weight":
                                                                      "bold",
                                                                  },
                                                                  attrs: {
                                                                    flat: "",
                                                                    "icon-right":
                                                                      "keyboard_arrow_right",
                                                                    color:
                                                                      "primary",
                                                                    size: "1.5rem",
                                                                    label:
                                                                      "Child Abuse",
                                                                    "no-caps":
                                                                      "",
                                                                    align:
                                                                      "left",
                                                                  },
                                                                  on: {
                                                                    click:
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        return _vm.openUrl(
                                                                          "https://azcrimevictimhelp.org/other/child-abuse"
                                                                        )
                                                                      },
                                                                  },
                                                                }),
                                                              ],
                                                              1
                                                            ),
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "col-4 q-pa-sm",
                                                              },
                                                              [
                                                                _c("q-btn", {
                                                                  staticClass:
                                                                    "otherBtn",
                                                                  staticStyle: {
                                                                    "font-weight":
                                                                      "bold",
                                                                  },
                                                                  attrs: {
                                                                    flat: "",
                                                                    "icon-right":
                                                                      "keyboard_arrow_right",
                                                                    color:
                                                                      "primary",
                                                                    size: "1.5rem",
                                                                    label:
                                                                      "Human Trafficking",
                                                                    "no-caps":
                                                                      "",
                                                                    align:
                                                                      "left",
                                                                  },
                                                                  on: {
                                                                    click:
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        return _vm.openUrl(
                                                                          "https://azcrimevictimhelp.org/other/human-trafficking"
                                                                        )
                                                                      },
                                                                  },
                                                                }),
                                                              ],
                                                              1
                                                            ),
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "col-4 q-pa-sm",
                                                              },
                                                              [
                                                                _c("q-btn", {
                                                                  staticClass:
                                                                    "otherBtn",
                                                                  staticStyle: {
                                                                    "font-weight":
                                                                      "bold",
                                                                  },
                                                                  attrs: {
                                                                    flat: "",
                                                                    "icon-right":
                                                                      "keyboard_arrow_right",
                                                                    color:
                                                                      "primary",
                                                                    size: "1.5rem",
                                                                    label:
                                                                      "Stalking",
                                                                    "no-caps":
                                                                      "",
                                                                    align:
                                                                      "left",
                                                                  },
                                                                  on: {
                                                                    click:
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        return _vm.openUrl(
                                                                          "https://azcrimevictimhelp.org/other/Stalking"
                                                                        )
                                                                      },
                                                                  },
                                                                }),
                                                              ],
                                                              1
                                                            ),
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "col-4 q-pa-sm",
                                                              },
                                                              [
                                                                _c("q-btn", {
                                                                  staticClass:
                                                                    "otherBtn",
                                                                  staticStyle: {
                                                                    "font-weight":
                                                                      "bold",
                                                                  },
                                                                  attrs: {
                                                                    flat: "",
                                                                    "icon-right":
                                                                      "keyboard_arrow_right",
                                                                    color:
                                                                      "primary",
                                                                    size: "1.5rem",
                                                                    label:
                                                                      "Bullying",
                                                                    "no-caps":
                                                                      "",
                                                                    align:
                                                                      "left",
                                                                  },
                                                                  on: {
                                                                    click:
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        return _vm.openUrl(
                                                                          "https://azcrimevictimhelp.org/other/Bullying"
                                                                        )
                                                                      },
                                                                  },
                                                                }),
                                                              ],
                                                              1
                                                            ),
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "col-4 q-pa-sm",
                                                              },
                                                              [
                                                                _c("q-btn", {
                                                                  staticClass:
                                                                    "otherBtn",
                                                                  staticStyle: {
                                                                    "font-weight":
                                                                      "bold",
                                                                  },
                                                                  attrs: {
                                                                    flat: "",
                                                                    "icon-right":
                                                                      "keyboard_arrow_right",
                                                                    color:
                                                                      "primary",
                                                                    size: "1.5rem",
                                                                    label:
                                                                      "Tech Crimes",
                                                                    "no-caps":
                                                                      "",
                                                                    align:
                                                                      "left",
                                                                  },
                                                                  on: {
                                                                    click:
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        return _vm.openUrl(
                                                                          "https://azcrimevictimhelp.org/other/tech-crimes"
                                                                        )
                                                                      },
                                                                  },
                                                                }),
                                                              ],
                                                              1
                                                            ),
                                                          ]
                                                        ),
                                                      ]
                                                    )
                                                  : _vm._e(),
                                                _vm.language == "ES"
                                                  ? _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "col-12 row q-pa-md items-center justify-center",
                                                      },
                                                      [
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "col-6 text-center",
                                                          },
                                                          [
                                                            _c(
                                                              "h5",
                                                              {
                                                                staticClass:
                                                                  "text-bold",
                                                                staticStyle: {
                                                                  color:
                                                                    "#00667e",
                                                                  width: "60%",
                                                                  margin:
                                                                    "auto",
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "\n                        Visite estos enlaces para encontrar más información\n                        sobre temas relacionados con sus respuestas:\n                      "
                                                                ),
                                                              ]
                                                            ),
                                                          ]
                                                        ),
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "col-6",
                                                          },
                                                          [
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "col-4 q-pa-sm",
                                                              },
                                                              [
                                                                _c("q-btn", {
                                                                  staticClass:
                                                                    "otherBtn",
                                                                  staticStyle: {
                                                                    "font-weight":
                                                                      "bold",
                                                                  },
                                                                  attrs: {
                                                                    flat: "",
                                                                    "icon-right":
                                                                      "keyboard_arrow_right",
                                                                    color:
                                                                      "primary",
                                                                    size: "1.5rem",
                                                                    label:
                                                                      "Violencia Doméstica",
                                                                    "no-caps":
                                                                      "",
                                                                    align:
                                                                      "left",
                                                                  },
                                                                  on: {
                                                                    click:
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        return _vm.openUrl(
                                                                          "https://azcrimevictimhelp.org/other/dating-violence"
                                                                        )
                                                                      },
                                                                  },
                                                                }),
                                                              ],
                                                              1
                                                            ),
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "col-4 q-pa-sm",
                                                              },
                                                              [
                                                                _c("q-btn", {
                                                                  staticClass:
                                                                    "otherBtn",
                                                                  staticStyle: {
                                                                    "font-weight":
                                                                      "bold",
                                                                  },
                                                                  attrs: {
                                                                    flat: "",
                                                                    "icon-right":
                                                                      "keyboard_arrow_right",
                                                                    color:
                                                                      "primary",
                                                                    size: "1.5rem",
                                                                    label:
                                                                      "Violencia Sexual",
                                                                    "no-caps":
                                                                      "",
                                                                    align:
                                                                      "left",
                                                                  },
                                                                  on: {
                                                                    click:
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        return _vm.openUrl(
                                                                          "https://azcrimevictimhelp.org/other/sexual-violence"
                                                                        )
                                                                      },
                                                                  },
                                                                }),
                                                              ],
                                                              1
                                                            ),
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "col-4 q-pa-sm",
                                                              },
                                                              [
                                                                _c("q-btn", {
                                                                  staticClass:
                                                                    "otherBtn",
                                                                  staticStyle: {
                                                                    "font-weight":
                                                                      "bold",
                                                                  },
                                                                  attrs: {
                                                                    flat: "",
                                                                    "icon-right":
                                                                      "keyboard_arrow_right",
                                                                    color:
                                                                      "primary",
                                                                    size: "1.5rem",
                                                                    label:
                                                                      "Maltrato de Mayores",
                                                                    "no-caps":
                                                                      "",
                                                                    align:
                                                                      "left",
                                                                  },
                                                                  on: {
                                                                    click:
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        return _vm.openUrl(
                                                                          "https://azcrimevictimhelp.org/other/elder-abuse"
                                                                        )
                                                                      },
                                                                  },
                                                                }),
                                                              ],
                                                              1
                                                            ),
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "col-4 q-pa-sm",
                                                              },
                                                              [
                                                                _c("q-btn", {
                                                                  staticClass:
                                                                    "otherBtn",
                                                                  staticStyle: {
                                                                    "font-weight":
                                                                      "bold",
                                                                  },
                                                                  attrs: {
                                                                    flat: "",
                                                                    "icon-right":
                                                                      "keyboard_arrow_right",
                                                                    color:
                                                                      "primary",
                                                                    size: "1.5rem",
                                                                    label:
                                                                      "Abuso Infantil",
                                                                    "no-caps":
                                                                      "",
                                                                    align:
                                                                      "left",
                                                                  },
                                                                  on: {
                                                                    click:
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        return _vm.openUrl(
                                                                          "https://azcrimevictimhelp.org/other/child-abuse"
                                                                        )
                                                                      },
                                                                  },
                                                                }),
                                                              ],
                                                              1
                                                            ),
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "col-4 q-pa-sm",
                                                              },
                                                              [
                                                                _c("q-btn", {
                                                                  staticClass:
                                                                    "otherBtn",
                                                                  staticStyle: {
                                                                    "font-weight":
                                                                      "bold",
                                                                  },
                                                                  attrs: {
                                                                    flat: "",
                                                                    "icon-right":
                                                                      "keyboard_arrow_right",
                                                                    color:
                                                                      "primary",
                                                                    size: "1.5rem",
                                                                    label:
                                                                      "Trata de Personas",
                                                                    "no-caps":
                                                                      "",
                                                                    align:
                                                                      "left",
                                                                  },
                                                                  on: {
                                                                    click:
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        return _vm.openUrl(
                                                                          "https://azcrimevictimhelp.org/other/human-trafficking"
                                                                        )
                                                                      },
                                                                  },
                                                                }),
                                                              ],
                                                              1
                                                            ),
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "col-4 q-pa-sm",
                                                              },
                                                              [
                                                                _c("q-btn", {
                                                                  staticClass:
                                                                    "otherBtn",
                                                                  staticStyle: {
                                                                    "font-weight":
                                                                      "bold",
                                                                  },
                                                                  attrs: {
                                                                    flat: "",
                                                                    "icon-right":
                                                                      "keyboard_arrow_right",
                                                                    color:
                                                                      "primary",
                                                                    size: "1.5rem",
                                                                    label:
                                                                      "Acecho",
                                                                    "no-caps":
                                                                      "",
                                                                    align:
                                                                      "left",
                                                                  },
                                                                  on: {
                                                                    click:
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        return _vm.openUrl(
                                                                          "https://azcrimevictimhelp.org/other/Stalking"
                                                                        )
                                                                      },
                                                                  },
                                                                }),
                                                              ],
                                                              1
                                                            ),
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "col-4 q-pa-sm",
                                                              },
                                                              [
                                                                _c("q-btn", {
                                                                  staticClass:
                                                                    "otherBtn",
                                                                  staticStyle: {
                                                                    "font-weight":
                                                                      "bold",
                                                                  },
                                                                  attrs: {
                                                                    flat: "",
                                                                    "icon-right":
                                                                      "keyboard_arrow_right",
                                                                    color:
                                                                      "primary",
                                                                    size: "1.5rem",
                                                                    label:
                                                                      "Acoso",
                                                                    "no-caps":
                                                                      "",
                                                                    align:
                                                                      "left",
                                                                  },
                                                                  on: {
                                                                    click:
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        return _vm.openUrl(
                                                                          "https://azcrimevictimhelp.org/other/Bullying"
                                                                        )
                                                                      },
                                                                  },
                                                                }),
                                                              ],
                                                              1
                                                            ),
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "col-4 q-pa-sm",
                                                              },
                                                              [
                                                                _c("q-btn", {
                                                                  staticClass:
                                                                    "otherBtn",
                                                                  staticStyle: {
                                                                    "font-weight":
                                                                      "bold",
                                                                  },
                                                                  attrs: {
                                                                    flat: "",
                                                                    "icon-right":
                                                                      "keyboard_arrow_right",
                                                                    color:
                                                                      "primary",
                                                                    size: "1.5rem",
                                                                    label:
                                                                      "Delitos Tecnológicos",
                                                                    "no-caps":
                                                                      "",
                                                                    align:
                                                                      "left",
                                                                  },
                                                                  on: {
                                                                    click:
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        return _vm.openUrl(
                                                                          "https://azcrimevictimhelp.org/other/tech-crimes"
                                                                        )
                                                                      },
                                                                  },
                                                                }),
                                                              ],
                                                              1
                                                            ),
                                                          ]
                                                        ),
                                                      ]
                                                    )
                                                  : _vm._e(),
                                              ])
                                            : _c("q-card", {}, [
                                                _c(
                                                  "div",
                                                  { staticClass: "row" },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "col-12 shadow-1",
                                                      },
                                                      [
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "row q-pa-none",
                                                          },
                                                          [
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "col-3 q-px-none",
                                                                staticStyle: {
                                                                  "background-color":
                                                                    "#82b8c4",
                                                                },
                                                              },
                                                              [
                                                                _vm.language ==
                                                                "EN"
                                                                  ? _c(
                                                                      "q-btn",
                                                                      {
                                                                        staticClass:
                                                                          "foundationFont notranslate full-width text-bold shadow-1",
                                                                        class: {
                                                                          activeBtnMobile:
                                                                            _vm.topic ==
                                                                            "about",
                                                                        },
                                                                        staticStyle:
                                                                          {
                                                                            "font-size":
                                                                              "1rem",
                                                                          },
                                                                        attrs: {
                                                                          flat: "",
                                                                          active:
                                                                            "primary",
                                                                          color:
                                                                            "white",
                                                                          label:
                                                                            "SUMMARY",
                                                                        },
                                                                        on: {
                                                                          click:
                                                                            function (
                                                                              $event
                                                                            ) {
                                                                              return _vm.makeActive(
                                                                                $event,
                                                                                "about"
                                                                              )
                                                                            },
                                                                        },
                                                                      }
                                                                    )
                                                                  : _vm._e(),
                                                                _vm.language ==
                                                                "ES"
                                                                  ? _c(
                                                                      "q-btn",
                                                                      {
                                                                        staticClass:
                                                                          "foundationFont notranslate full-width text-bold shadow-1",
                                                                        class: {
                                                                          activeBtnMobile:
                                                                            _vm.topic ==
                                                                            "about",
                                                                        },
                                                                        staticStyle:
                                                                          {
                                                                            "font-size":
                                                                              "1rem",
                                                                          },
                                                                        attrs: {
                                                                          flat: "",
                                                                          color:
                                                                            "white",
                                                                          label:
                                                                            "RESUMEN",
                                                                        },
                                                                        on: {
                                                                          click:
                                                                            function (
                                                                              $event
                                                                            ) {
                                                                              return _vm.makeActive(
                                                                                $event,
                                                                                "about"
                                                                              )
                                                                            },
                                                                        },
                                                                      }
                                                                    )
                                                                  : _vm._e(),
                                                              ],
                                                              1
                                                            ),
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "col-3 q-px-none",
                                                                staticStyle: {
                                                                  "background-color":
                                                                    "#82b8c4",
                                                                },
                                                              },
                                                              [
                                                                _vm.language ==
                                                                "EN"
                                                                  ? _c(
                                                                      "q-btn",
                                                                      {
                                                                        staticClass:
                                                                          "foundationFont notranslate full-width text-bold shadow-1",
                                                                        class: {
                                                                          activeBtnMobile:
                                                                            _vm.topic ==
                                                                            "nextSteps",
                                                                        },
                                                                        staticStyle:
                                                                          {
                                                                            "font-size":
                                                                              "1rem",
                                                                          },
                                                                        attrs: {
                                                                          "no-caps":
                                                                            "",
                                                                          flat: "",
                                                                          color:
                                                                            "white",
                                                                          label:
                                                                            "NEXT STEPS",
                                                                        },
                                                                        on: {
                                                                          click:
                                                                            function (
                                                                              $event
                                                                            ) {
                                                                              return _vm.makeActive(
                                                                                $event,
                                                                                "nextSteps"
                                                                              )
                                                                            },
                                                                        },
                                                                      }
                                                                    )
                                                                  : _vm._e(),
                                                                _vm.language ==
                                                                "ES"
                                                                  ? _c(
                                                                      "q-btn",
                                                                      {
                                                                        staticClass:
                                                                          "foundationFont notranslate full-width text-bold shadow-1",
                                                                        class: {
                                                                          activeBtnMobile:
                                                                            _vm.topic ==
                                                                            "nextSteps",
                                                                        },
                                                                        staticStyle:
                                                                          {
                                                                            "font-size":
                                                                              "1rem",
                                                                          },
                                                                        attrs: {
                                                                          "no-caps":
                                                                            "",
                                                                          flat: "",
                                                                          color:
                                                                            "white",
                                                                          label:
                                                                            "PRÓXIMOS PASOS",
                                                                        },
                                                                        on: {
                                                                          click:
                                                                            function (
                                                                              $event
                                                                            ) {
                                                                              return _vm.makeActive(
                                                                                $event,
                                                                                "nextSteps"
                                                                              )
                                                                            },
                                                                        },
                                                                      }
                                                                    )
                                                                  : _vm._e(),
                                                              ],
                                                              1
                                                            ),
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "col-3 q-px-none",
                                                                staticStyle: {
                                                                  "background-color":
                                                                    "#82b8c4",
                                                                },
                                                              },
                                                              [
                                                                _vm.language ==
                                                                "EN"
                                                                  ? _c(
                                                                      "q-btn",
                                                                      {
                                                                        staticClass:
                                                                          "foundationFont notranslate full-width text-bold shadow-1",
                                                                        class: {
                                                                          activeBtnMobile:
                                                                            _vm.topic ==
                                                                            "findHelp",
                                                                        },
                                                                        staticStyle:
                                                                          {
                                                                            "font-size":
                                                                              "1rem",
                                                                          },
                                                                        attrs: {
                                                                          "no-caps":
                                                                            "",
                                                                          flat: "",
                                                                          color:
                                                                            "white",
                                                                          label:
                                                                            "RESOURCES",
                                                                        },
                                                                        on: {
                                                                          click:
                                                                            function (
                                                                              $event
                                                                            ) {
                                                                              return _vm.makeActive(
                                                                                $event,
                                                                                "findHelp"
                                                                              )
                                                                            },
                                                                        },
                                                                      }
                                                                    )
                                                                  : _vm._e(),
                                                                _vm.language ==
                                                                "ES"
                                                                  ? _c(
                                                                      "q-btn",
                                                                      {
                                                                        staticClass:
                                                                          "foundationFont notranslate full-width text-bold shadow-1",
                                                                        class: {
                                                                          activeBtnMobile:
                                                                            _vm.topic ==
                                                                            "findHelp",
                                                                        },
                                                                        staticStyle:
                                                                          {
                                                                            "font-size":
                                                                              "1rem",
                                                                          },
                                                                        attrs: {
                                                                          "no-caps":
                                                                            "",
                                                                          flat: "",
                                                                          color:
                                                                            "white",
                                                                          label:
                                                                            "RECURSOS",
                                                                        },
                                                                        on: {
                                                                          click:
                                                                            function (
                                                                              $event
                                                                            ) {
                                                                              return _vm.makeActive(
                                                                                $event,
                                                                                "findHelp"
                                                                              )
                                                                            },
                                                                        },
                                                                      }
                                                                    )
                                                                  : _vm._e(),
                                                              ],
                                                              1
                                                            ),
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "col-3 q-px-none",
                                                                staticStyle: {
                                                                  "background-color":
                                                                    "#82b8c4",
                                                                },
                                                              },
                                                              [
                                                                _vm.language ==
                                                                "EN"
                                                                  ? _c(
                                                                      "q-btn",
                                                                      {
                                                                        staticClass:
                                                                          "foundationFont notranslate full-width text-bold shadow-1",
                                                                        class: {
                                                                          activeBtnMobile:
                                                                            _vm.topic ==
                                                                            "moreInfo",
                                                                        },
                                                                        staticStyle:
                                                                          {
                                                                            "font-size":
                                                                              "1rem",
                                                                          },
                                                                        attrs: {
                                                                          "no-caps":
                                                                            "",
                                                                          flat: "",
                                                                          color:
                                                                            "white",
                                                                          label:
                                                                            "MORE INFO",
                                                                        },
                                                                        on: {
                                                                          click:
                                                                            function (
                                                                              $event
                                                                            ) {
                                                                              return _vm.makeActive(
                                                                                $event,
                                                                                "moreInfo"
                                                                              )
                                                                            },
                                                                        },
                                                                      }
                                                                    )
                                                                  : _vm._e(),
                                                                _vm.language ==
                                                                "ES"
                                                                  ? _c(
                                                                      "q-btn",
                                                                      {
                                                                        staticClass:
                                                                          "foundationFont notranslate full-width text-bold shadow-1",
                                                                        class: {
                                                                          activeBtnMobile:
                                                                            _vm.topic ==
                                                                            "moreInfo",
                                                                        },
                                                                        staticStyle:
                                                                          {
                                                                            "font-size":
                                                                              "1rem",
                                                                          },
                                                                        attrs: {
                                                                          "no-caps":
                                                                            "",
                                                                          flat: "",
                                                                          color:
                                                                            "white",
                                                                          label:
                                                                            "MÁS INFORMACIÓN",
                                                                        },
                                                                        on: {
                                                                          click:
                                                                            function (
                                                                              $event
                                                                            ) {
                                                                              return _vm.makeActive(
                                                                                $event,
                                                                                "moreInfo"
                                                                              )
                                                                            },
                                                                        },
                                                                      }
                                                                    )
                                                                  : _vm._e(),
                                                              ],
                                                              1
                                                            ),
                                                          ]
                                                        ),
                                                      ]
                                                    ),
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "col-12 content-areaMobile",
                                                        attrs: {
                                                          color: "accent",
                                                        },
                                                      },
                                                      [
                                                        _vm.topic == "about"
                                                          ? _c(
                                                              "q-card-section",
                                                              [
                                                                _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "flex flex-center",
                                                                  },
                                                                  [
                                                                    _vm.language ==
                                                                    "EN"
                                                                      ? _c(
                                                                          "p",
                                                                          {
                                                                            staticClass:
                                                                              "text-bold q-pt-lg",
                                                                            staticStyle:
                                                                              {
                                                                                "font-size":
                                                                                  "1.5rem",
                                                                                color:
                                                                                  "#00667e",
                                                                              },
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              "\n                            About Your Situation\n                          "
                                                                            ),
                                                                          ]
                                                                        )
                                                                      : _vm._e(),
                                                                    _vm.language ==
                                                                    "ES"
                                                                      ? _c(
                                                                          "p",
                                                                          {
                                                                            staticClass:
                                                                              "text-bold q-pt-lg",
                                                                            staticStyle:
                                                                              {
                                                                                "font-size":
                                                                                  "1.5rem",
                                                                                color:
                                                                                  "#00667e",
                                                                              },
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              "\n                            Sobre su situación\n                          "
                                                                            ),
                                                                          ]
                                                                        )
                                                                      : _vm._e(),
                                                                  ]
                                                                ),
                                                                _vm.expanded !=
                                                                ""
                                                                  ? _c(
                                                                      "div",
                                                                      {
                                                                        staticClass:
                                                                          "text-center q-pa-lg text-body1",
                                                                        staticStyle:
                                                                          {
                                                                            "font-size":
                                                                              "20px",
                                                                            "line-height":
                                                                              "1.5",
                                                                          },
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          "\n                          " +
                                                                            _vm._s(
                                                                              _vm
                                                                                .UserReport[
                                                                                _vm
                                                                                  .expanded
                                                                              ]
                                                                                .AboutSituation[0]
                                                                            ) +
                                                                            "\n                        "
                                                                        ),
                                                                      ]
                                                                    )
                                                                  : _c(
                                                                      "div",
                                                                      {},
                                                                      [
                                                                        _vm._v(
                                                                          "Not Available"
                                                                        ),
                                                                      ]
                                                                    ),
                                                              ]
                                                            )
                                                          : _vm._e(),
                                                        _vm.topic == "nextSteps"
                                                          ? _c(
                                                              "q-card-section",
                                                              [
                                                                _vm.UserReport[
                                                                  _vm.expanded
                                                                ].NextStep1 &&
                                                                _vm.UserReport[
                                                                  _vm.expanded
                                                                ].NextStep2 &&
                                                                _vm.UserReport[
                                                                  _vm.expanded
                                                                ].NextStep3
                                                                  ? _c(
                                                                      "div",
                                                                      {
                                                                        staticClass:
                                                                          "text-center q-pa-sm",
                                                                        staticStyle:
                                                                          {
                                                                            "line-height":
                                                                              "1.5",
                                                                          },
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "q-stepper",
                                                                          {
                                                                            ref: "stepper",
                                                                            refInFor: true,
                                                                            staticStyle:
                                                                              {
                                                                                "font-size":
                                                                                  "20px",
                                                                                "min-height":
                                                                                  "325px",
                                                                              },
                                                                            attrs:
                                                                              {
                                                                                animated:
                                                                                  "",
                                                                                "header-nav":
                                                                                  "",
                                                                                "keep-alive":
                                                                                  "",
                                                                                "active-color":
                                                                                  "primary",
                                                                                "inactive-color":
                                                                                  "secondary",
                                                                                infinite:
                                                                                  "",
                                                                                swipeable:
                                                                                  "",
                                                                              },
                                                                            scopedSlots:
                                                                              _vm._u(
                                                                                [
                                                                                  {
                                                                                    key: "navigation",
                                                                                    fn: function () {
                                                                                      return [
                                                                                        _c(
                                                                                          "q-stepper-navigation",
                                                                                          [
                                                                                            _vm.language ==
                                                                                            "EN"
                                                                                              ? _c(
                                                                                                  "q-btn",
                                                                                                  {
                                                                                                    attrs:
                                                                                                      {
                                                                                                        color:
                                                                                                          "primary",
                                                                                                        label:
                                                                                                          "Continue",
                                                                                                      },
                                                                                                    on: {
                                                                                                      click:
                                                                                                        function (
                                                                                                          $event
                                                                                                        ) {
                                                                                                          _vm.$refs.stepper[
                                                                                                            _vm
                                                                                                              .stepperTracker[
                                                                                                              category
                                                                                                            ]
                                                                                                          ].next()
                                                                                                        },
                                                                                                    },
                                                                                                  }
                                                                                                )
                                                                                              : _vm._e(),
                                                                                            _vm.language ==
                                                                                            "ES"
                                                                                              ? _c(
                                                                                                  "q-btn",
                                                                                                  {
                                                                                                    attrs:
                                                                                                      {
                                                                                                        color:
                                                                                                          "primary",
                                                                                                        label:
                                                                                                          "Continuar",
                                                                                                      },
                                                                                                    on: {
                                                                                                      click:
                                                                                                        function (
                                                                                                          $event
                                                                                                        ) {
                                                                                                          _vm.$refs.stepper[
                                                                                                            _vm
                                                                                                              .stepperTracker[
                                                                                                              category
                                                                                                            ]
                                                                                                          ].next()
                                                                                                        },
                                                                                                    },
                                                                                                  }
                                                                                                )
                                                                                              : _vm._e(),
                                                                                          ],
                                                                                          1
                                                                                        ),
                                                                                      ]
                                                                                    },
                                                                                    proxy: true,
                                                                                  },
                                                                                ],
                                                                                null,
                                                                                true
                                                                              ),
                                                                            model:
                                                                              {
                                                                                value:
                                                                                  _vm.step,
                                                                                callback:
                                                                                  function (
                                                                                    $$v
                                                                                  ) {
                                                                                    _vm.step =
                                                                                      $$v
                                                                                  },
                                                                                expression:
                                                                                  "step",
                                                                              },
                                                                          },
                                                                          [
                                                                            _c(
                                                                              "q-step",
                                                                              {
                                                                                attrs:
                                                                                  {
                                                                                    name: 1,
                                                                                    prefix:
                                                                                      "1",
                                                                                    title:
                                                                                      _vm
                                                                                        .UserReport[
                                                                                        _vm
                                                                                          .expanded
                                                                                      ]
                                                                                        .NextStep1Title[0],
                                                                                  },
                                                                              },
                                                                              [
                                                                                _c(
                                                                                  "div",
                                                                                  {
                                                                                    domProps:
                                                                                      {
                                                                                        innerHTML:
                                                                                          _vm._s(
                                                                                            _vm
                                                                                              .UserReport[
                                                                                              _vm
                                                                                                .expanded
                                                                                            ]
                                                                                              .NextStep1[0]
                                                                                          ),
                                                                                      },
                                                                                  }
                                                                                ),
                                                                              ]
                                                                            ),
                                                                            _c(
                                                                              "q-step",
                                                                              {
                                                                                attrs:
                                                                                  {
                                                                                    name: 2,
                                                                                    prefix:
                                                                                      "2",
                                                                                    title:
                                                                                      _vm
                                                                                        .UserReport[
                                                                                        _vm
                                                                                          .expanded
                                                                                      ]
                                                                                        .NextStep2Title[0],
                                                                                  },
                                                                              },
                                                                              [
                                                                                _c(
                                                                                  "div",
                                                                                  {
                                                                                    domProps:
                                                                                      {
                                                                                        innerHTML:
                                                                                          _vm._s(
                                                                                            _vm
                                                                                              .UserReport[
                                                                                              _vm
                                                                                                .expanded
                                                                                            ]
                                                                                              .NextStep2[0]
                                                                                          ),
                                                                                      },
                                                                                  }
                                                                                ),
                                                                              ]
                                                                            ),
                                                                            _c(
                                                                              "q-step",
                                                                              {
                                                                                attrs:
                                                                                  {
                                                                                    name: 3,
                                                                                    prefix:
                                                                                      "3",
                                                                                    title:
                                                                                      _vm
                                                                                        .UserReport[
                                                                                        _vm
                                                                                          .expanded
                                                                                      ]
                                                                                        .NextStep3Title[0],
                                                                                  },
                                                                              },
                                                                              [
                                                                                _c(
                                                                                  "div",
                                                                                  {
                                                                                    domProps:
                                                                                      {
                                                                                        innerHTML:
                                                                                          _vm._s(
                                                                                            _vm
                                                                                              .UserReport[
                                                                                              _vm
                                                                                                .expanded
                                                                                            ]
                                                                                              .NextStep3[0]
                                                                                          ),
                                                                                      },
                                                                                  }
                                                                                ),
                                                                              ]
                                                                            ),
                                                                          ],
                                                                          1
                                                                        ),
                                                                      ],
                                                                      1
                                                                    )
                                                                  : _c(
                                                                      "div",
                                                                      {},
                                                                      [
                                                                        _vm._v(
                                                                          "\n                          There are no next steps for you.\n                        "
                                                                        ),
                                                                      ]
                                                                    ),
                                                              ]
                                                            )
                                                          : _vm._e(),
                                                        _vm.topic == "findHelp"
                                                          ? _c(
                                                              "q-card-section",
                                                              [
                                                                _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "q-pa-lg",
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "div",
                                                                      {
                                                                        staticClass:
                                                                          "row items-start",
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "div",
                                                                          {
                                                                            staticClass:
                                                                              "col-12 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-xs-12 column",
                                                                          },
                                                                          _vm._l(
                                                                            _vm.legalResources,
                                                                            function (
                                                                              resource
                                                                            ) {
                                                                              return _c(
                                                                                "div",
                                                                                {
                                                                                  key: resource.id,
                                                                                  staticClass:
                                                                                    "col q-pa-md",
                                                                                  staticStyle:
                                                                                    {
                                                                                      "min-width":
                                                                                        "70%",
                                                                                      "min-height":
                                                                                        "70%",
                                                                                    },
                                                                                },
                                                                                [
                                                                                  _c(
                                                                                    "q-card",
                                                                                    {
                                                                                      staticClass:
                                                                                        "text-primary text-left resourceCard",
                                                                                      staticStyle:
                                                                                        {
                                                                                          "min-height":
                                                                                            "325px",
                                                                                          "font-size":
                                                                                            "20px",
                                                                                          "line-height":
                                                                                            "1.5",
                                                                                        },
                                                                                      attrs:
                                                                                        {
                                                                                          flat: "",
                                                                                        },
                                                                                    },
                                                                                    [
                                                                                      _c(
                                                                                        "q-card-section",
                                                                                        {
                                                                                          staticClass:
                                                                                            "resource-header",
                                                                                        },
                                                                                        [
                                                                                          _c(
                                                                                            "div",
                                                                                            {
                                                                                              staticClass:
                                                                                                "text-h5 text-bold",
                                                                                            },
                                                                                            [
                                                                                              _vm._v(
                                                                                                "\n                                      " +
                                                                                                  _vm._s(
                                                                                                    resource.organizationName
                                                                                                  ) +
                                                                                                  "\n                                    "
                                                                                              ),
                                                                                            ]
                                                                                          ),
                                                                                          _c(
                                                                                            "div",
                                                                                            {
                                                                                              staticClass:
                                                                                                "text-subtitle1",
                                                                                            },
                                                                                            [
                                                                                              _vm._v(
                                                                                                "\n                                      " +
                                                                                                  _vm._s(
                                                                                                    resource.description
                                                                                                  ) +
                                                                                                  "\n                                    "
                                                                                              ),
                                                                                            ]
                                                                                          ),
                                                                                        ]
                                                                                      ),
                                                                                      _c(
                                                                                        "q-separator",
                                                                                        {
                                                                                          staticClass:
                                                                                            "secondary",
                                                                                          attrs:
                                                                                            {
                                                                                              inset:
                                                                                                "",
                                                                                            },
                                                                                        }
                                                                                      ),
                                                                                      _c(
                                                                                        "q-card-section",
                                                                                        [
                                                                                          _c(
                                                                                            "div",
                                                                                            {
                                                                                              staticClass:
                                                                                                "column",
                                                                                            },
                                                                                            [
                                                                                              resource.officeName
                                                                                                ? _c(
                                                                                                    "div",
                                                                                                    {
                                                                                                      staticClass:
                                                                                                        "col",
                                                                                                    },
                                                                                                    [
                                                                                                      _c(
                                                                                                        "p",
                                                                                                        [
                                                                                                          _vm._v(
                                                                                                            "\n                                          " +
                                                                                                              _vm._s(
                                                                                                                resource.officeName
                                                                                                              ) +
                                                                                                              "\n                                        "
                                                                                                          ),
                                                                                                        ]
                                                                                                      ),
                                                                                                    ]
                                                                                                  )
                                                                                                : _vm._e(),
                                                                                              resource.address
                                                                                                ? _c(
                                                                                                    "div",
                                                                                                    {
                                                                                                      staticClass:
                                                                                                        "col",
                                                                                                    },
                                                                                                    [
                                                                                                      _c(
                                                                                                        "p",
                                                                                                        [
                                                                                                          _vm._v(
                                                                                                            "\n                                          " +
                                                                                                              _vm._s(
                                                                                                                resource.address
                                                                                                              ) +
                                                                                                              "\n                                          "
                                                                                                          ),
                                                                                                          _c(
                                                                                                            "br"
                                                                                                          ),
                                                                                                          _vm._v(
                                                                                                            "\n                                          " +
                                                                                                              _vm._s(
                                                                                                                resource.city
                                                                                                              ) +
                                                                                                              ",\n                                          " +
                                                                                                              _vm._s(
                                                                                                                resource.state
                                                                                                              ) +
                                                                                                              "\n                                          " +
                                                                                                              _vm._s(
                                                                                                                resource.zip
                                                                                                              ) +
                                                                                                              "\n                                        "
                                                                                                          ),
                                                                                                        ]
                                                                                                      ),
                                                                                                    ]
                                                                                                  )
                                                                                                : _vm._e(),
                                                                                              resource.phoneNumber
                                                                                                ? _c(
                                                                                                    "div",
                                                                                                    {
                                                                                                      staticClass:
                                                                                                        "col",
                                                                                                    },
                                                                                                    [
                                                                                                      _c(
                                                                                                        "p",
                                                                                                        [
                                                                                                          _vm._v(
                                                                                                            "\n                                          Direct:\n                                          "
                                                                                                          ),
                                                                                                          _c(
                                                                                                            "strong",
                                                                                                            [
                                                                                                              _vm._v(
                                                                                                                "\n                                            " +
                                                                                                                  _vm._s(
                                                                                                                    resource.phoneNumber
                                                                                                                  )
                                                                                                              ),
                                                                                                            ]
                                                                                                          ),
                                                                                                        ]
                                                                                                      ),
                                                                                                      resource.tollFreeNumber
                                                                                                        ? _c(
                                                                                                            "p",
                                                                                                            [
                                                                                                              _vm._v(
                                                                                                                "\n                                          Toll Free Number:\n                                          "
                                                                                                              ),
                                                                                                              _c(
                                                                                                                "strong",
                                                                                                                [
                                                                                                                  _vm._v(
                                                                                                                    "\n                                            " +
                                                                                                                      _vm._s(
                                                                                                                        resource.tollFreeNumber
                                                                                                                      )
                                                                                                                  ),
                                                                                                                ]
                                                                                                              ),
                                                                                                            ]
                                                                                                          )
                                                                                                        : _vm._e(),
                                                                                                    ]
                                                                                                  )
                                                                                                : _vm._e(),
                                                                                              resource.websiteAddress
                                                                                                ? _c(
                                                                                                    "div",
                                                                                                    {
                                                                                                      staticClass:
                                                                                                        "col",
                                                                                                    },
                                                                                                    [
                                                                                                      _c(
                                                                                                        "p",
                                                                                                        [
                                                                                                          _c(
                                                                                                            "a",
                                                                                                            {
                                                                                                              staticClass:
                                                                                                                "dont-break-out",
                                                                                                              staticStyle:
                                                                                                                {
                                                                                                                  color:
                                                                                                                    "#00667e",
                                                                                                                },
                                                                                                              attrs:
                                                                                                                {
                                                                                                                  target:
                                                                                                                    "blank",
                                                                                                                  href: resource.websiteAddress,
                                                                                                                },
                                                                                                            },
                                                                                                            [
                                                                                                              _vm._v(
                                                                                                                "Website"
                                                                                                              ),
                                                                                                            ]
                                                                                                          ),
                                                                                                        ]
                                                                                                      ),
                                                                                                    ]
                                                                                                  )
                                                                                                : _vm._e(),
                                                                                              resource.emailAddress
                                                                                                ? _c(
                                                                                                    "div",
                                                                                                    {
                                                                                                      staticClass:
                                                                                                        "col",
                                                                                                    },
                                                                                                    [
                                                                                                      _c(
                                                                                                        "p",
                                                                                                        [
                                                                                                          _c(
                                                                                                            "strong",
                                                                                                            [
                                                                                                              _vm._v(
                                                                                                                "Email:"
                                                                                                              ),
                                                                                                            ]
                                                                                                          ),
                                                                                                          _vm._v(
                                                                                                            "\n                                          " +
                                                                                                              _vm._s(
                                                                                                                resource.email
                                                                                                              ) +
                                                                                                              "\n                                        "
                                                                                                          ),
                                                                                                        ]
                                                                                                      ),
                                                                                                    ]
                                                                                                  )
                                                                                                : _vm._e(),
                                                                                            ]
                                                                                          ),
                                                                                        ]
                                                                                      ),
                                                                                    ],
                                                                                    1
                                                                                  ),
                                                                                ],
                                                                                1
                                                                              )
                                                                            }
                                                                          ),
                                                                          0
                                                                        ),
                                                                        _c(
                                                                          "div",
                                                                          {
                                                                            staticClass:
                                                                              "col-12 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-xs-12 column",
                                                                          },
                                                                          _vm._l(
                                                                            _vm.localResources,
                                                                            function (
                                                                              resource
                                                                            ) {
                                                                              return _c(
                                                                                "div",
                                                                                {
                                                                                  key: resource.id,
                                                                                  staticClass:
                                                                                    "col q-pa-md",
                                                                                  staticStyle:
                                                                                    {
                                                                                      "min-width":
                                                                                        "70%",
                                                                                      "min-height":
                                                                                        "70%",
                                                                                    },
                                                                                },
                                                                                [
                                                                                  _c(
                                                                                    "q-card",
                                                                                    {
                                                                                      staticClass:
                                                                                        "text-primary text-left resourceCard",
                                                                                      staticStyle:
                                                                                        {
                                                                                          "min-height":
                                                                                            "325px",
                                                                                          "font-size":
                                                                                            "20px",
                                                                                          "line-height":
                                                                                            "1.5",
                                                                                        },
                                                                                      attrs:
                                                                                        {
                                                                                          flat: "",
                                                                                        },
                                                                                    },
                                                                                    [
                                                                                      _c(
                                                                                        "q-card-section",
                                                                                        {
                                                                                          staticClass:
                                                                                            "resource-header",
                                                                                        },
                                                                                        [
                                                                                          _c(
                                                                                            "div",
                                                                                            {
                                                                                              staticClass:
                                                                                                "text-h5 text-bold",
                                                                                            },
                                                                                            [
                                                                                              _vm._v(
                                                                                                "\n                                      " +
                                                                                                  _vm._s(
                                                                                                    resource.organizationName
                                                                                                  ) +
                                                                                                  "\n                                    "
                                                                                              ),
                                                                                            ]
                                                                                          ),
                                                                                          _c(
                                                                                            "div",
                                                                                            {
                                                                                              staticClass:
                                                                                                "text-subtitle1",
                                                                                            },
                                                                                            [
                                                                                              _vm._v(
                                                                                                "\n                                      " +
                                                                                                  _vm._s(
                                                                                                    resource.description
                                                                                                  ) +
                                                                                                  "\n                                    "
                                                                                              ),
                                                                                            ]
                                                                                          ),
                                                                                        ]
                                                                                      ),
                                                                                      _c(
                                                                                        "q-separator",
                                                                                        {
                                                                                          staticClass:
                                                                                            "secondary",
                                                                                          attrs:
                                                                                            {
                                                                                              inset:
                                                                                                "",
                                                                                            },
                                                                                        }
                                                                                      ),
                                                                                      _c(
                                                                                        "q-card-section",
                                                                                        [
                                                                                          _c(
                                                                                            "div",
                                                                                            {
                                                                                              staticClass:
                                                                                                "column",
                                                                                            },
                                                                                            [
                                                                                              resource.officeName
                                                                                                ? _c(
                                                                                                    "div",
                                                                                                    {
                                                                                                      staticClass:
                                                                                                        "col",
                                                                                                    },
                                                                                                    [
                                                                                                      _c(
                                                                                                        "p",
                                                                                                        [
                                                                                                          _vm._v(
                                                                                                            "\n                                          " +
                                                                                                              _vm._s(
                                                                                                                resource.officeName
                                                                                                              ) +
                                                                                                              "\n                                        "
                                                                                                          ),
                                                                                                        ]
                                                                                                      ),
                                                                                                    ]
                                                                                                  )
                                                                                                : _vm._e(),
                                                                                              resource.address
                                                                                                ? _c(
                                                                                                    "div",
                                                                                                    {
                                                                                                      staticClass:
                                                                                                        "col",
                                                                                                    },
                                                                                                    [
                                                                                                      _c(
                                                                                                        "p",
                                                                                                        [
                                                                                                          _vm._v(
                                                                                                            "\n                                          " +
                                                                                                              _vm._s(
                                                                                                                resource.address
                                                                                                              ) +
                                                                                                              "\n                                          "
                                                                                                          ),
                                                                                                          _c(
                                                                                                            "br"
                                                                                                          ),
                                                                                                          _vm._v(
                                                                                                            "\n                                          " +
                                                                                                              _vm._s(
                                                                                                                resource.city
                                                                                                              ) +
                                                                                                              ",\n                                          " +
                                                                                                              _vm._s(
                                                                                                                resource.state
                                                                                                              ) +
                                                                                                              "\n                                          " +
                                                                                                              _vm._s(
                                                                                                                resource.zip
                                                                                                              ) +
                                                                                                              "\n                                        "
                                                                                                          ),
                                                                                                        ]
                                                                                                      ),
                                                                                                    ]
                                                                                                  )
                                                                                                : _vm._e(),
                                                                                              resource.phoneNumber
                                                                                                ? _c(
                                                                                                    "div",
                                                                                                    {
                                                                                                      staticClass:
                                                                                                        "col",
                                                                                                    },
                                                                                                    [
                                                                                                      _c(
                                                                                                        "p",
                                                                                                        [
                                                                                                          _vm._v(
                                                                                                            "\n                                          Direct:\n                                          "
                                                                                                          ),
                                                                                                          _c(
                                                                                                            "strong",
                                                                                                            [
                                                                                                              _vm._v(
                                                                                                                "\n                                            " +
                                                                                                                  _vm._s(
                                                                                                                    resource.phoneNumber
                                                                                                                  )
                                                                                                              ),
                                                                                                            ]
                                                                                                          ),
                                                                                                        ]
                                                                                                      ),
                                                                                                      resource.tollFreeNumber
                                                                                                        ? _c(
                                                                                                            "p",
                                                                                                            [
                                                                                                              _vm._v(
                                                                                                                "\n                                          Toll Free Number:\n                                          "
                                                                                                              ),
                                                                                                              _c(
                                                                                                                "strong",
                                                                                                                [
                                                                                                                  _vm._v(
                                                                                                                    "\n                                            " +
                                                                                                                      _vm._s(
                                                                                                                        resource.tollFreeNumber
                                                                                                                      )
                                                                                                                  ),
                                                                                                                ]
                                                                                                              ),
                                                                                                            ]
                                                                                                          )
                                                                                                        : _vm._e(),
                                                                                                    ]
                                                                                                  )
                                                                                                : _vm._e(),
                                                                                              resource.websiteAddress
                                                                                                ? _c(
                                                                                                    "div",
                                                                                                    {
                                                                                                      staticClass:
                                                                                                        "col",
                                                                                                    },
                                                                                                    [
                                                                                                      _c(
                                                                                                        "p",
                                                                                                        [
                                                                                                          _c(
                                                                                                            "a",
                                                                                                            {
                                                                                                              staticClass:
                                                                                                                "dont-break-out",
                                                                                                              staticStyle:
                                                                                                                {
                                                                                                                  color:
                                                                                                                    "#00667e",
                                                                                                                },
                                                                                                              attrs:
                                                                                                                {
                                                                                                                  target:
                                                                                                                    "blank",
                                                                                                                  href: resource.websiteAddress,
                                                                                                                },
                                                                                                              on: {
                                                                                                                click:
                                                                                                                  function (
                                                                                                                    $event
                                                                                                                  ) {
                                                                                                                    return _vm.track(
                                                                                                                      resource.topic,
                                                                                                                      resource.websiteAddress
                                                                                                                    )
                                                                                                                  },
                                                                                                              },
                                                                                                            },
                                                                                                            [
                                                                                                              _vm._v(
                                                                                                                "Website"
                                                                                                              ),
                                                                                                            ]
                                                                                                          ),
                                                                                                        ]
                                                                                                      ),
                                                                                                    ]
                                                                                                  )
                                                                                                : _vm._e(),
                                                                                              resource.emailAddress
                                                                                                ? _c(
                                                                                                    "div",
                                                                                                    {
                                                                                                      staticClass:
                                                                                                        "col",
                                                                                                    },
                                                                                                    [
                                                                                                      _c(
                                                                                                        "p",
                                                                                                        [
                                                                                                          _c(
                                                                                                            "strong",
                                                                                                            [
                                                                                                              _vm._v(
                                                                                                                "Email:"
                                                                                                              ),
                                                                                                            ]
                                                                                                          ),
                                                                                                          _vm._v(
                                                                                                            "\n                                          " +
                                                                                                              _vm._s(
                                                                                                                resource.email
                                                                                                              ) +
                                                                                                              "\n                                        "
                                                                                                          ),
                                                                                                        ]
                                                                                                      ),
                                                                                                    ]
                                                                                                  )
                                                                                                : _vm._e(),
                                                                                            ]
                                                                                          ),
                                                                                        ]
                                                                                      ),
                                                                                    ],
                                                                                    1
                                                                                  ),
                                                                                ],
                                                                                1
                                                                              )
                                                                            }
                                                                          ),
                                                                          0
                                                                        ),
                                                                      ]
                                                                    ),
                                                                  ]
                                                                ),
                                                              ]
                                                            )
                                                          : _vm._e(),
                                                        _vm.topic == "moreInfo"
                                                          ? _c(
                                                              "q-card-section",
                                                              [
                                                                _vm.UserReport[
                                                                  _vm.expanded
                                                                ].MoreInfo
                                                                  ? _c(
                                                                      "div",
                                                                      [
                                                                        _vm._l(
                                                                          _vm
                                                                            .UserReport[
                                                                            _vm
                                                                              .expanded
                                                                          ]
                                                                            .MoreInfo,
                                                                          function (
                                                                            moreInfo
                                                                          ) {
                                                                            return _c(
                                                                              "div",
                                                                              {
                                                                                key: moreInfo,
                                                                                staticClass:
                                                                                  "text-h6 text-center q-pa-md",
                                                                                staticStyle:
                                                                                  {
                                                                                    "font-size":
                                                                                      "20px",
                                                                                    "line-height":
                                                                                      "1.5",
                                                                                    color:
                                                                                      "#0c667d",
                                                                                  },
                                                                              },
                                                                              [
                                                                                _c(
                                                                                  "div",
                                                                                  {
                                                                                    staticClass:
                                                                                      "col-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 column",
                                                                                    domProps:
                                                                                      {
                                                                                        innerHTML:
                                                                                          _vm._s(
                                                                                            moreInfo
                                                                                          ),
                                                                                      },
                                                                                  }
                                                                                ),
                                                                              ]
                                                                            )
                                                                          }
                                                                        ),
                                                                        _c(
                                                                          "br"
                                                                        ),
                                                                      ],
                                                                      2
                                                                    )
                                                                  : _vm._e(),
                                                              ]
                                                            )
                                                          : _vm._e(),
                                                      ],
                                                      1
                                                    ),
                                                  ]
                                                ),
                                              ]),
                                        ],
                                        1
                                      )
                                    }),
                                    _c("q-separator"),
                                  ],
                                  2
                                ),
                              ],
                              1
                            ),
                          ]
                        )
                      : _vm._e(),
                  ]),
                ])
              : _vm._e(),
            _vm.$q.platform.is.mobile
              ? _c(
                  "div",
                  { staticClass: "row items-center justify-center bottomSide" },
                  [
                    _vm.categories.length != 0
                      ? _c("div", { staticClass: "col-12 justify-center" }, [
                          _c(
                            "div",
                            { staticClass: "q-pa-sm" },
                            [
                              _c(
                                "q-list",
                                {},
                                [
                                  _vm._l(_vm.categories, function (category) {
                                    return _c(
                                      "q-expansion-item",
                                      {
                                        key: category,
                                        staticClass: "q-pa-sm",
                                        staticStyle: {
                                          width: "100% !important",
                                        },
                                        attrs: {
                                          value: _vm.expanded == category,
                                          group: category,
                                          "header-style": { height: "4rem" },
                                          "expand-separator": "",
                                          "expand-icon-class": "text-white",
                                          "header-class":
                                            "bg-cyan-9 text-bold text-center",
                                        },
                                        on: {
                                          click: () => {
                                            _vm.expanded = category
                                            _vm.$refs.stepper[
                                              _vm.stepperTracker[category]
                                            ].goTo(1)
                                          },
                                          show: () => {
                                            _vm.topic = "about"
                                          },
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "header",
                                              fn: function () {
                                                return [
                                                  _c(
                                                    "q-item-section",
                                                    {
                                                      staticClass:
                                                        "foundationFont",
                                                      staticStyle: {
                                                        "font-size": "3vh",
                                                        color: "#fff",
                                                      },
                                                    },
                                                    [_vm._v(_vm._s(category))]
                                                  ),
                                                ]
                                              },
                                              proxy: true,
                                            },
                                          ],
                                          null,
                                          true
                                        ),
                                      },
                                      [
                                        category == "Other" ||
                                        category == "otro"
                                          ? _c("q-card", [
                                              _vm.language == "EN"
                                                ? _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "col-12 row q-pa-md items-center justify-center",
                                                    },
                                                    [
                                                      _c(
                                                        "h5",
                                                        {
                                                          staticClass:
                                                            "text-bold",
                                                          staticStyle: {
                                                            color: "#00667e",
                                                            width: "90%",
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            "\n                    Visit these links to find more information on topics\n                    related to your answers:\n                  "
                                                          ),
                                                        ]
                                                      ),
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "col-12 q-pa-sm",
                                                        },
                                                        [
                                                          _c("q-btn", {
                                                            staticClass:
                                                              "otherBtn",
                                                            staticStyle: {
                                                              "font-weight":
                                                                "bold",
                                                            },
                                                            attrs: {
                                                              flat: "",
                                                              "icon-right":
                                                                "keyboard_arrow_right",
                                                              color: "primary",
                                                              size: "1.25rem",
                                                              label:
                                                                "Domestic Violence",
                                                              "no-caps": "",
                                                              align: "left",
                                                            },
                                                            on: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                return _vm.openUrl(
                                                                  "https://azcrimevictimhelp.org/other/dating-violence"
                                                                )
                                                              },
                                                            },
                                                          }),
                                                        ],
                                                        1
                                                      ),
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "col-12 q-pa-sm",
                                                        },
                                                        [
                                                          _c("q-btn", {
                                                            staticClass:
                                                              "otherBtn",
                                                            staticStyle: {
                                                              "font-weight":
                                                                "bold",
                                                            },
                                                            attrs: {
                                                              flat: "",
                                                              "icon-right":
                                                                "keyboard_arrow_right",
                                                              color: "primary",
                                                              size: "1.25rem",
                                                              label:
                                                                "Sexual Violence",
                                                              "no-caps": "",
                                                              align: "left",
                                                            },
                                                            on: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                return _vm.openUrl(
                                                                  "https://azcrimevictimhelp.org/other/sexual-violence"
                                                                )
                                                              },
                                                            },
                                                          }),
                                                        ],
                                                        1
                                                      ),
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "col-12 q-pa-sm",
                                                        },
                                                        [
                                                          _c("q-btn", {
                                                            staticClass:
                                                              "otherBtn",
                                                            staticStyle: {
                                                              "font-weight":
                                                                "bold",
                                                            },
                                                            attrs: {
                                                              flat: "",
                                                              "icon-right":
                                                                "keyboard_arrow_right",
                                                              color: "primary",
                                                              size: "1.25rem",
                                                              label:
                                                                "Elder Abuse",
                                                              "no-caps": "",
                                                              align: "left",
                                                            },
                                                            on: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                return _vm.openUrl(
                                                                  "https://azcrimevictimhelp.org/other/elder-abuse"
                                                                )
                                                              },
                                                            },
                                                          }),
                                                        ],
                                                        1
                                                      ),
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "col-12 q-pa-sm",
                                                        },
                                                        [
                                                          _c("q-btn", {
                                                            staticClass:
                                                              "otherBtn",
                                                            staticStyle: {
                                                              "font-weight":
                                                                "bold",
                                                            },
                                                            attrs: {
                                                              flat: "",
                                                              "icon-right":
                                                                "keyboard_arrow_right",
                                                              color: "primary",
                                                              size: "1.25rem",
                                                              label:
                                                                "Child Abuse",
                                                              "no-caps": "",
                                                              align: "left",
                                                            },
                                                            on: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                return _vm.openUrl(
                                                                  "https://azcrimevictimhelp.org/other/child-abuse"
                                                                )
                                                              },
                                                            },
                                                          }),
                                                        ],
                                                        1
                                                      ),
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "col-12 q-pa-sm",
                                                        },
                                                        [
                                                          _c("q-btn", {
                                                            staticClass:
                                                              "otherBtn",
                                                            staticStyle: {
                                                              "font-weight":
                                                                "bold",
                                                            },
                                                            attrs: {
                                                              flat: "",
                                                              "icon-right":
                                                                "keyboard_arrow_right",
                                                              color: "primary",
                                                              size: "1.25rem",
                                                              label:
                                                                "Human Trafficking",
                                                              "no-caps": "",
                                                              align: "left",
                                                            },
                                                            on: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                return _vm.openUrl(
                                                                  "https://azcrimevictimhelp.org/other/human-trafficking"
                                                                )
                                                              },
                                                            },
                                                          }),
                                                        ],
                                                        1
                                                      ),
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "col-12 q-pa-sm",
                                                        },
                                                        [
                                                          _c("q-btn", {
                                                            staticClass:
                                                              "otherBtn",
                                                            staticStyle: {
                                                              "font-weight":
                                                                "bold",
                                                            },
                                                            attrs: {
                                                              flat: "",
                                                              "icon-right":
                                                                "keyboard_arrow_right",
                                                              color: "primary",
                                                              size: "1.25rem",
                                                              label: "Stalking",
                                                              "no-caps": "",
                                                              align: "left",
                                                            },
                                                            on: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                return _vm.openUrl(
                                                                  "https://azcrimevictimhelp.org/other/Stalking"
                                                                )
                                                              },
                                                            },
                                                          }),
                                                        ],
                                                        1
                                                      ),
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "col-12 q-pa-sm",
                                                        },
                                                        [
                                                          _c("q-btn", {
                                                            staticClass:
                                                              "otherBtn",
                                                            staticStyle: {
                                                              "font-weight":
                                                                "bold",
                                                            },
                                                            attrs: {
                                                              flat: "",
                                                              "icon-right":
                                                                "keyboard_arrow_right",
                                                              color: "primary",
                                                              size: "1.25rem",
                                                              label: "Bullying",
                                                              "no-caps": "",
                                                              align: "left",
                                                            },
                                                            on: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                return _vm.openUrl(
                                                                  "https://azcrimevictimhelp.org/other/Bullying"
                                                                )
                                                              },
                                                            },
                                                          }),
                                                        ],
                                                        1
                                                      ),
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "col-12 q-pa-sm",
                                                        },
                                                        [
                                                          _c("q-btn", {
                                                            staticClass:
                                                              "otherBtn",
                                                            staticStyle: {
                                                              "font-weight":
                                                                "bold",
                                                            },
                                                            attrs: {
                                                              flat: "",
                                                              "icon-right":
                                                                "keyboard_arrow_right",
                                                              color: "primary",
                                                              size: "1.25rem",
                                                              label:
                                                                "Tech Crimes",
                                                              "no-caps": "",
                                                              align: "left",
                                                            },
                                                            on: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                return _vm.openUrl(
                                                                  "https://azcrimevictimhelp.org/other/tech-crimes"
                                                                )
                                                              },
                                                            },
                                                          }),
                                                        ],
                                                        1
                                                      ),
                                                    ]
                                                  )
                                                : _vm._e(),
                                              _vm.language == "ES"
                                                ? _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "col-12 row q-pa-md items-center justify-center",
                                                    },
                                                    [
                                                      _c(
                                                        "h5",
                                                        {
                                                          staticClass:
                                                            "text-bold",
                                                          staticStyle: {
                                                            color: "#00667e",
                                                            width: "90%",
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            "\n                    Visite estos enlaces para encontrar más información sobre\n                    temas relacionados con sus respuestas:\n                  "
                                                          ),
                                                        ]
                                                      ),
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "col-12 q-pa-sm",
                                                        },
                                                        [
                                                          _c("q-btn", {
                                                            staticClass:
                                                              "otherBtn",
                                                            staticStyle: {
                                                              "font-weight":
                                                                "bold",
                                                            },
                                                            attrs: {
                                                              flat: "",
                                                              "icon-right":
                                                                "keyboard_arrow_right",
                                                              color: "primary",
                                                              size: "1.25rem",
                                                              label:
                                                                "Violencia Doméstica",
                                                              "no-caps": "",
                                                              align: "left",
                                                            },
                                                            on: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                return _vm.openUrl(
                                                                  "https://azcrimevictimhelp.org/other/dating-violence"
                                                                )
                                                              },
                                                            },
                                                          }),
                                                        ],
                                                        1
                                                      ),
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "col-12 q-pa-sm",
                                                        },
                                                        [
                                                          _c("q-btn", {
                                                            staticClass:
                                                              "otherBtn",
                                                            staticStyle: {
                                                              "font-weight":
                                                                "bold",
                                                            },
                                                            attrs: {
                                                              flat: "",
                                                              "icon-right":
                                                                "keyboard_arrow_right",
                                                              color: "primary",
                                                              size: "1.25rem",
                                                              label:
                                                                "Violencia Sexual",
                                                              "no-caps": "",
                                                              align: "left",
                                                            },
                                                            on: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                return _vm.openUrl(
                                                                  "https://azcrimevictimhelp.org/other/sexual-violence"
                                                                )
                                                              },
                                                            },
                                                          }),
                                                        ],
                                                        1
                                                      ),
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "col-12 q-pa-sm",
                                                        },
                                                        [
                                                          _c("q-btn", {
                                                            staticClass:
                                                              "otherBtn",
                                                            staticStyle: {
                                                              "font-weight":
                                                                "bold",
                                                            },
                                                            attrs: {
                                                              flat: "",
                                                              "icon-right":
                                                                "keyboard_arrow_right",
                                                              color: "primary",
                                                              size: "1.25rem",
                                                              label:
                                                                "Maltrato de Mayores",
                                                              "no-caps": "",
                                                              align: "left",
                                                            },
                                                            on: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                return _vm.openUrl(
                                                                  "https://azcrimevictimhelp.org/other/elder-abuse"
                                                                )
                                                              },
                                                            },
                                                          }),
                                                        ],
                                                        1
                                                      ),
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "col-12 q-pa-sm",
                                                        },
                                                        [
                                                          _c("q-btn", {
                                                            staticClass:
                                                              "otherBtn",
                                                            staticStyle: {
                                                              "font-weight":
                                                                "bold",
                                                            },
                                                            attrs: {
                                                              flat: "",
                                                              "icon-right":
                                                                "keyboard_arrow_right",
                                                              color: "primary",
                                                              size: "1.25rem",
                                                              label:
                                                                "Abuso Infantil",
                                                              "no-caps": "",
                                                              align: "left",
                                                            },
                                                            on: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                return _vm.openUrl(
                                                                  "https://azcrimevictimhelp.org/other/child-abuse"
                                                                )
                                                              },
                                                            },
                                                          }),
                                                        ],
                                                        1
                                                      ),
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "col-12 q-pa-sm",
                                                        },
                                                        [
                                                          _c("q-btn", {
                                                            staticClass:
                                                              "otherBtn",
                                                            staticStyle: {
                                                              "font-weight":
                                                                "bold",
                                                            },
                                                            attrs: {
                                                              flat: "",
                                                              "icon-right":
                                                                "keyboard_arrow_right",
                                                              color: "primary",
                                                              size: "1.25rem",
                                                              label:
                                                                "Trata de Personas",
                                                              "no-caps": "",
                                                              align: "left",
                                                            },
                                                            on: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                return _vm.openUrl(
                                                                  "https://azcrimevictimhelp.org/other/human-trafficking"
                                                                )
                                                              },
                                                            },
                                                          }),
                                                        ],
                                                        1
                                                      ),
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "col-12 q-pa-sm",
                                                        },
                                                        [
                                                          _c("q-btn", {
                                                            staticClass:
                                                              "otherBtn",
                                                            staticStyle: {
                                                              "font-weight":
                                                                "bold",
                                                            },
                                                            attrs: {
                                                              flat: "",
                                                              "icon-right":
                                                                "keyboard_arrow_right",
                                                              color: "primary",
                                                              size: "1.25rem",
                                                              label: "Accecho",
                                                              "no-caps": "",
                                                              align: "left",
                                                            },
                                                            on: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                return _vm.openUrl(
                                                                  "https://azcrimevictimhelp.org/other/Stalking"
                                                                )
                                                              },
                                                            },
                                                          }),
                                                        ],
                                                        1
                                                      ),
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "col-12 q-pa-sm",
                                                        },
                                                        [
                                                          _c("q-btn", {
                                                            staticClass:
                                                              "otherBtn",
                                                            staticStyle: {
                                                              "font-weight":
                                                                "bold",
                                                            },
                                                            attrs: {
                                                              flat: "",
                                                              "icon-right":
                                                                "keyboard_arrow_right",
                                                              color: "primary",
                                                              size: "1.25rem",
                                                              label: "Acoso",
                                                              "no-caps": "",
                                                              align: "left",
                                                            },
                                                            on: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                return _vm.openUrl(
                                                                  "https://azcrimevictimhelp.org/other/Bullying"
                                                                )
                                                              },
                                                            },
                                                          }),
                                                        ],
                                                        1
                                                      ),
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "col-12 q-pa-sm",
                                                        },
                                                        [
                                                          _c("q-btn", {
                                                            staticClass:
                                                              "otherBtn",
                                                            staticStyle: {
                                                              "font-weight":
                                                                "bold",
                                                            },
                                                            attrs: {
                                                              flat: "",
                                                              "icon-right":
                                                                "keyboard_arrow_right",
                                                              color: "primary",
                                                              size: "1.25rem",
                                                              label:
                                                                "Delitos Tecnológicos",
                                                              "no-caps": "",
                                                              align: "left",
                                                            },
                                                            on: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                return _vm.openUrl(
                                                                  "https://azcrimevictimhelp.org/other/tech-crimes"
                                                                )
                                                              },
                                                            },
                                                          }),
                                                        ],
                                                        1
                                                      ),
                                                    ]
                                                  )
                                                : _vm._e(),
                                            ])
                                          : _c(
                                              "q-card",
                                              { attrs: { flat: "" } },
                                              [
                                                _c(
                                                  "div",
                                                  { staticClass: "row" },
                                                  [
                                                    _c(
                                                      "div",
                                                      { staticClass: "col-12" },
                                                      [
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "row q-pa-none",
                                                          },
                                                          [
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "col-3 q-px-none",
                                                                staticStyle: {
                                                                  "background-color":
                                                                    "#82b8c4",
                                                                },
                                                              },
                                                              [
                                                                _c("q-btn", {
                                                                  staticClass:
                                                                    "foundationFont full-width content-btnMobile shadow-1",
                                                                  class: {
                                                                    activeBtnMobile:
                                                                      _vm.topic ==
                                                                      "about",
                                                                  },
                                                                  staticStyle: {
                                                                    "font-size":
                                                                      "1.25rem",
                                                                  },
                                                                  attrs: {
                                                                    flat: "",
                                                                    color:
                                                                      "white",
                                                                    icon: "info",
                                                                  },
                                                                  on: {
                                                                    click:
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        return _vm.makeActive(
                                                                          $event,
                                                                          "about"
                                                                        )
                                                                      },
                                                                  },
                                                                }),
                                                              ],
                                                              1
                                                            ),
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "col-3 q-px-none",
                                                                staticStyle: {
                                                                  "background-color":
                                                                    "#82b8c4",
                                                                },
                                                              },
                                                              [
                                                                _c("q-btn", {
                                                                  staticClass:
                                                                    "foundationFont full-width content-btnMobile shadow-1",
                                                                  class: {
                                                                    activeBtnMobile:
                                                                      _vm.topic ==
                                                                      "nextSteps",
                                                                  },
                                                                  staticStyle: {
                                                                    "font-size":
                                                                      "1.25rem",
                                                                  },
                                                                  attrs: {
                                                                    flat: "",
                                                                    color:
                                                                      "white",
                                                                    icon: "format_list_numbered",
                                                                  },
                                                                  on: {
                                                                    click:
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        return _vm.makeActive(
                                                                          $event,
                                                                          "nextSteps"
                                                                        )
                                                                      },
                                                                  },
                                                                }),
                                                              ],
                                                              1
                                                            ),
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "col-3 q-px-none",
                                                                staticStyle: {
                                                                  "background-color":
                                                                    "#82b8c4",
                                                                },
                                                              },
                                                              [
                                                                _c("q-btn", {
                                                                  staticClass:
                                                                    "foundationFont full-width content-btnMobile shadow-1",
                                                                  class: {
                                                                    activeBtnMobile:
                                                                      _vm.topic ==
                                                                      "findHelp",
                                                                  },
                                                                  staticStyle: {
                                                                    "font-size":
                                                                      "1.25rem",
                                                                  },
                                                                  attrs: {
                                                                    flat: "",
                                                                    color:
                                                                      "white",
                                                                    icon: "help",
                                                                  },
                                                                  on: {
                                                                    click:
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        return _vm.makeActive(
                                                                          $event,
                                                                          "findHelp"
                                                                        )
                                                                      },
                                                                  },
                                                                }),
                                                              ],
                                                              1
                                                            ),
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "col-3 q-px-none",
                                                                staticStyle: {
                                                                  "background-color":
                                                                    "#82b8c4",
                                                                },
                                                              },
                                                              [
                                                                _c("q-btn", {
                                                                  staticClass:
                                                                    "foundationFont full-width content-btnMobile shadow-1",
                                                                  class: {
                                                                    activeBtnMobile:
                                                                      _vm.topic ==
                                                                      "moreInfo",
                                                                  },
                                                                  staticStyle: {
                                                                    "font-size":
                                                                      "1.25rem",
                                                                  },
                                                                  attrs: {
                                                                    flat: "",
                                                                    color:
                                                                      "white",
                                                                    icon: "screen_share",
                                                                  },
                                                                  on: {
                                                                    click:
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        return _vm.makeActive(
                                                                          $event,
                                                                          "moreInfo"
                                                                        )
                                                                      },
                                                                  },
                                                                }),
                                                              ],
                                                              1
                                                            ),
                                                          ]
                                                        ),
                                                      ]
                                                    ),
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "col-12 content-areaMobile",
                                                        attrs: {
                                                          color: "accent",
                                                        },
                                                      },
                                                      [
                                                        _vm.topic == "about"
                                                          ? _c(
                                                              "q-card-section",
                                                              [
                                                                _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "flex flex-center",
                                                                  },
                                                                  [
                                                                    _vm.language ==
                                                                    "EN"
                                                                      ? _c(
                                                                          "p",
                                                                          {
                                                                            staticClass:
                                                                              "text-bold q-pt-lg",
                                                                            staticStyle:
                                                                              {
                                                                                "font-size":
                                                                                  "1.25rem",
                                                                                color:
                                                                                  "#00667e",
                                                                              },
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              "\n                          About Your Situation\n                        "
                                                                            ),
                                                                          ]
                                                                        )
                                                                      : _vm._e(),
                                                                    _vm.language ==
                                                                    "ES"
                                                                      ? _c(
                                                                          "p",
                                                                          {
                                                                            staticClass:
                                                                              "text-bold q-pt-lg",
                                                                            staticStyle:
                                                                              {
                                                                                "font-size":
                                                                                  "1.25rem",
                                                                                color:
                                                                                  "#00667e",
                                                                              },
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              "\n                          Sobre su situación\n                        "
                                                                            ),
                                                                          ]
                                                                        )
                                                                      : _vm._e(),
                                                                  ]
                                                                ),
                                                                _vm.expanded !=
                                                                ""
                                                                  ? _c(
                                                                      "div",
                                                                      {
                                                                        staticClass:
                                                                          "text-center q-pa-lg text-body1",
                                                                        staticStyle:
                                                                          {
                                                                            "font-size":
                                                                              "20px",
                                                                            "line-height":
                                                                              "1.5",
                                                                          },
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          "\n                        " +
                                                                            _vm._s(
                                                                              _vm
                                                                                .UserReport[
                                                                                _vm
                                                                                  .expanded
                                                                              ]
                                                                                .AboutSituation[0]
                                                                            ) +
                                                                            "\n                      "
                                                                        ),
                                                                      ]
                                                                    )
                                                                  : _c(
                                                                      "div",
                                                                      {},
                                                                      [
                                                                        _vm._v(
                                                                          "Not Available"
                                                                        ),
                                                                      ]
                                                                    ),
                                                              ]
                                                            )
                                                          : _vm._e(),
                                                        _vm.topic == "nextSteps"
                                                          ? _c(
                                                              "q-card-section",
                                                              [
                                                                _vm.UserReport[
                                                                  _vm.expanded
                                                                ].NextStep1 &&
                                                                _vm.UserReport[
                                                                  _vm.expanded
                                                                ].NextStep2 &&
                                                                _vm.UserReport[
                                                                  _vm.expanded
                                                                ].NextStep3
                                                                  ? _c(
                                                                      "div",
                                                                      {
                                                                        staticClass:
                                                                          "text-center q-pa-md",
                                                                        staticStyle:
                                                                          {
                                                                            "font-size":
                                                                              "16px",
                                                                            "line-height":
                                                                              "1.5",
                                                                          },
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "q-stepper",
                                                                          {
                                                                            ref: "stepper",
                                                                            refInFor: true,
                                                                            staticStyle:
                                                                              {
                                                                                "font-size":
                                                                                  "20px",
                                                                              },
                                                                            attrs:
                                                                              {
                                                                                animated:
                                                                                  "",
                                                                                "header-nav":
                                                                                  "",
                                                                                "keep-alive":
                                                                                  "",
                                                                                "active-color":
                                                                                  "primary",
                                                                                "inactive-color":
                                                                                  "secondary",
                                                                                infinite:
                                                                                  "",
                                                                                swipeable:
                                                                                  "",
                                                                              },
                                                                            scopedSlots:
                                                                              _vm._u(
                                                                                [
                                                                                  {
                                                                                    key: "navigation",
                                                                                    fn: function () {
                                                                                      return [
                                                                                        _c(
                                                                                          "q-stepper-navigation",
                                                                                          [
                                                                                            _vm.language ==
                                                                                            "EN"
                                                                                              ? _c(
                                                                                                  "q-btn",
                                                                                                  {
                                                                                                    attrs:
                                                                                                      {
                                                                                                        color:
                                                                                                          "primary",
                                                                                                        label:
                                                                                                          "Continue",
                                                                                                      },
                                                                                                    on: {
                                                                                                      click:
                                                                                                        function (
                                                                                                          $event
                                                                                                        ) {
                                                                                                          _vm.$refs.stepper[
                                                                                                            _vm
                                                                                                              .stepperTracker[
                                                                                                              category
                                                                                                            ]
                                                                                                          ].next()
                                                                                                        },
                                                                                                    },
                                                                                                  }
                                                                                                )
                                                                                              : _vm._e(),
                                                                                            _vm.language ==
                                                                                            "ES"
                                                                                              ? _c(
                                                                                                  "q-btn",
                                                                                                  {
                                                                                                    attrs:
                                                                                                      {
                                                                                                        color:
                                                                                                          "primary",
                                                                                                        label:
                                                                                                          "Continuar",
                                                                                                      },
                                                                                                    on: {
                                                                                                      click:
                                                                                                        function (
                                                                                                          $event
                                                                                                        ) {
                                                                                                          _vm.$refs.stepper[
                                                                                                            _vm
                                                                                                              .stepperTracker[
                                                                                                              category
                                                                                                            ]
                                                                                                          ].next()
                                                                                                        },
                                                                                                    },
                                                                                                  }
                                                                                                )
                                                                                              : _vm._e(),
                                                                                          ],
                                                                                          1
                                                                                        ),
                                                                                      ]
                                                                                    },
                                                                                    proxy: true,
                                                                                  },
                                                                                ],
                                                                                null,
                                                                                true
                                                                              ),
                                                                            model:
                                                                              {
                                                                                value:
                                                                                  _vm.step,
                                                                                callback:
                                                                                  function (
                                                                                    $$v
                                                                                  ) {
                                                                                    _vm.step =
                                                                                      $$v
                                                                                  },
                                                                                expression:
                                                                                  "step",
                                                                              },
                                                                          },
                                                                          [
                                                                            _c(
                                                                              "q-step",
                                                                              {
                                                                                attrs:
                                                                                  {
                                                                                    name: 1,
                                                                                    prefix:
                                                                                      "1",
                                                                                    title:
                                                                                      _vm
                                                                                        .UserReport[
                                                                                        _vm
                                                                                          .expanded
                                                                                      ]
                                                                                        .NextStep1Title[0],
                                                                                  },
                                                                              },
                                                                              [
                                                                                _c(
                                                                                  "div",
                                                                                  {
                                                                                    domProps:
                                                                                      {
                                                                                        innerHTML:
                                                                                          _vm._s(
                                                                                            _vm
                                                                                              .UserReport[
                                                                                              _vm
                                                                                                .expanded
                                                                                            ]
                                                                                              .NextStep1[0]
                                                                                          ),
                                                                                      },
                                                                                  }
                                                                                ),
                                                                              ]
                                                                            ),
                                                                            _c(
                                                                              "q-step",
                                                                              {
                                                                                attrs:
                                                                                  {
                                                                                    name: 2,
                                                                                    prefix:
                                                                                      "2",
                                                                                    title:
                                                                                      _vm
                                                                                        .UserReport[
                                                                                        _vm
                                                                                          .expanded
                                                                                      ]
                                                                                        .NextStep2Title[0],
                                                                                  },
                                                                              },
                                                                              [
                                                                                _c(
                                                                                  "div",
                                                                                  {
                                                                                    domProps:
                                                                                      {
                                                                                        innerHTML:
                                                                                          _vm._s(
                                                                                            _vm
                                                                                              .UserReport[
                                                                                              _vm
                                                                                                .expanded
                                                                                            ]
                                                                                              .NextStep2[0]
                                                                                          ),
                                                                                      },
                                                                                  }
                                                                                ),
                                                                              ]
                                                                            ),
                                                                            _c(
                                                                              "q-step",
                                                                              {
                                                                                attrs:
                                                                                  {
                                                                                    name: 3,
                                                                                    prefix:
                                                                                      "3",
                                                                                    title:
                                                                                      _vm
                                                                                        .UserReport[
                                                                                        _vm
                                                                                          .expanded
                                                                                      ]
                                                                                        .NextStep3Title[0],
                                                                                  },
                                                                              },
                                                                              [
                                                                                _c(
                                                                                  "div",
                                                                                  {
                                                                                    domProps:
                                                                                      {
                                                                                        innerHTML:
                                                                                          _vm._s(
                                                                                            _vm
                                                                                              .UserReport[
                                                                                              _vm
                                                                                                .expanded
                                                                                            ]
                                                                                              .NextStep3[0]
                                                                                          ),
                                                                                      },
                                                                                  }
                                                                                ),
                                                                              ]
                                                                            ),
                                                                          ],
                                                                          1
                                                                        ),
                                                                      ],
                                                                      1
                                                                    )
                                                                  : _c(
                                                                      "div",
                                                                      {},
                                                                      [
                                                                        _vm._v(
                                                                          "\n                        There are no next steps for you.\n                      "
                                                                        ),
                                                                      ]
                                                                    ),
                                                              ]
                                                            )
                                                          : _vm._e(),
                                                        _vm.topic == "findHelp"
                                                          ? _c(
                                                              "q-card-section",
                                                              [
                                                                _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "q-pa-sm",
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "div",
                                                                      {
                                                                        staticClass:
                                                                          "row items-start",
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "div",
                                                                          {
                                                                            staticClass:
                                                                              "col-12 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-xs-12 column",
                                                                          },
                                                                          _vm._l(
                                                                            _vm.legalResources,
                                                                            function (
                                                                              resource
                                                                            ) {
                                                                              return _c(
                                                                                "div",
                                                                                {
                                                                                  key: resource.id,
                                                                                  staticClass:
                                                                                    "col-12 q-pa-xs",
                                                                                  staticStyle:
                                                                                    {
                                                                                      "min-width":
                                                                                        "70%",
                                                                                      "min-height":
                                                                                        "70%",
                                                                                    },
                                                                                },
                                                                                [
                                                                                  _c(
                                                                                    "q-card",
                                                                                    {
                                                                                      staticClass:
                                                                                        "text-primary text-left resourceCard",
                                                                                      attrs:
                                                                                        {
                                                                                          flat: "",
                                                                                        },
                                                                                    },
                                                                                    [
                                                                                      _c(
                                                                                        "q-card-section",
                                                                                        {
                                                                                          staticClass:
                                                                                            "resource-header",
                                                                                        },
                                                                                        [
                                                                                          _c(
                                                                                            "div",
                                                                                            {
                                                                                              staticClass:
                                                                                                "text-h5 text-bold",
                                                                                            },
                                                                                            [
                                                                                              _vm._v(
                                                                                                "\n                                    " +
                                                                                                  _vm._s(
                                                                                                    resource.organizationName
                                                                                                  ) +
                                                                                                  "\n                                  "
                                                                                              ),
                                                                                            ]
                                                                                          ),
                                                                                          _c(
                                                                                            "div",
                                                                                            {
                                                                                              staticClass:
                                                                                                "text-subtitle1",
                                                                                            },
                                                                                            [
                                                                                              _vm._v(
                                                                                                "\n                                    " +
                                                                                                  _vm._s(
                                                                                                    resource.description
                                                                                                  ) +
                                                                                                  "\n                                  "
                                                                                              ),
                                                                                            ]
                                                                                          ),
                                                                                        ]
                                                                                      ),
                                                                                      _c(
                                                                                        "q-separator",
                                                                                        {
                                                                                          staticClass:
                                                                                            "secondary",
                                                                                          attrs:
                                                                                            {
                                                                                              inset:
                                                                                                "",
                                                                                            },
                                                                                        }
                                                                                      ),
                                                                                      _c(
                                                                                        "q-card-section",
                                                                                        [
                                                                                          _c(
                                                                                            "div",
                                                                                            {
                                                                                              staticClass:
                                                                                                "column",
                                                                                            },
                                                                                            [
                                                                                              resource.officeName
                                                                                                ? _c(
                                                                                                    "div",
                                                                                                    {
                                                                                                      staticClass:
                                                                                                        "col",
                                                                                                    },
                                                                                                    [
                                                                                                      _c(
                                                                                                        "p",
                                                                                                        [
                                                                                                          _vm._v(
                                                                                                            "\n                                        " +
                                                                                                              _vm._s(
                                                                                                                resource.officeName
                                                                                                              ) +
                                                                                                              "\n                                      "
                                                                                                          ),
                                                                                                        ]
                                                                                                      ),
                                                                                                    ]
                                                                                                  )
                                                                                                : _vm._e(),
                                                                                              resource.address
                                                                                                ? _c(
                                                                                                    "div",
                                                                                                    {
                                                                                                      staticClass:
                                                                                                        "col",
                                                                                                    },
                                                                                                    [
                                                                                                      _c(
                                                                                                        "p",
                                                                                                        [
                                                                                                          _vm._v(
                                                                                                            "\n                                        " +
                                                                                                              _vm._s(
                                                                                                                resource.address
                                                                                                              ) +
                                                                                                              "\n                                        "
                                                                                                          ),
                                                                                                          _c(
                                                                                                            "br"
                                                                                                          ),
                                                                                                          _vm._v(
                                                                                                            "\n                                        " +
                                                                                                              _vm._s(
                                                                                                                resource.city
                                                                                                              ) +
                                                                                                              ",\n                                        " +
                                                                                                              _vm._s(
                                                                                                                resource.state
                                                                                                              ) +
                                                                                                              "\n                                        " +
                                                                                                              _vm._s(
                                                                                                                resource.zip
                                                                                                              ) +
                                                                                                              "\n                                      "
                                                                                                          ),
                                                                                                        ]
                                                                                                      ),
                                                                                                    ]
                                                                                                  )
                                                                                                : _vm._e(),
                                                                                              resource.phoneNumber
                                                                                                ? _c(
                                                                                                    "div",
                                                                                                    {
                                                                                                      staticClass:
                                                                                                        "col",
                                                                                                    },
                                                                                                    [
                                                                                                      _c(
                                                                                                        "p",
                                                                                                        [
                                                                                                          _vm._v(
                                                                                                            "\n                                        Direct:\n                                        "
                                                                                                          ),
                                                                                                          _c(
                                                                                                            "strong",
                                                                                                            [
                                                                                                              _vm._v(
                                                                                                                _vm._s(
                                                                                                                  resource.phoneNumber
                                                                                                                )
                                                                                                              ),
                                                                                                            ]
                                                                                                          ),
                                                                                                        ]
                                                                                                      ),
                                                                                                      resource.tollFreeNumber
                                                                                                        ? _c(
                                                                                                            "p",
                                                                                                            [
                                                                                                              _vm._v(
                                                                                                                "\n                                        Toll Free Number:\n                                        "
                                                                                                              ),
                                                                                                              _c(
                                                                                                                "strong",
                                                                                                                [
                                                                                                                  _vm._v(
                                                                                                                    _vm._s(
                                                                                                                      resource.tollFreeNumber
                                                                                                                    )
                                                                                                                  ),
                                                                                                                ]
                                                                                                              ),
                                                                                                            ]
                                                                                                          )
                                                                                                        : _vm._e(),
                                                                                                    ]
                                                                                                  )
                                                                                                : _vm._e(),
                                                                                              resource.websiteAddress
                                                                                                ? _c(
                                                                                                    "div",
                                                                                                    {
                                                                                                      staticClass:
                                                                                                        "col",
                                                                                                    },
                                                                                                    [
                                                                                                      _c(
                                                                                                        "a",
                                                                                                        {
                                                                                                          staticClass:
                                                                                                            "dont-break-out",
                                                                                                          staticStyle:
                                                                                                            {
                                                                                                              color:
                                                                                                                "#00667e",
                                                                                                            },
                                                                                                          attrs:
                                                                                                            {
                                                                                                              target:
                                                                                                                "blank",
                                                                                                              href: resource.websiteAddress,
                                                                                                            },
                                                                                                          on: {
                                                                                                            click:
                                                                                                              function (
                                                                                                                $event
                                                                                                              ) {
                                                                                                                return _vm.track(
                                                                                                                  resource.topic,
                                                                                                                  resource.websiteAddress
                                                                                                                )
                                                                                                              },
                                                                                                          },
                                                                                                        },
                                                                                                        [
                                                                                                          _vm._v(
                                                                                                            "Website"
                                                                                                          ),
                                                                                                        ]
                                                                                                      ),
                                                                                                    ]
                                                                                                  )
                                                                                                : _vm._e(),
                                                                                              resource.emailAddress
                                                                                                ? _c(
                                                                                                    "div",
                                                                                                    {
                                                                                                      staticClass:
                                                                                                        "col",
                                                                                                    },
                                                                                                    [
                                                                                                      _c(
                                                                                                        "p",
                                                                                                        [
                                                                                                          _c(
                                                                                                            "strong",
                                                                                                            [
                                                                                                              _vm._v(
                                                                                                                "Email:"
                                                                                                              ),
                                                                                                            ]
                                                                                                          ),
                                                                                                          _vm._v(
                                                                                                            "\n                                        " +
                                                                                                              _vm._s(
                                                                                                                resource.email
                                                                                                              ) +
                                                                                                              "\n                                      "
                                                                                                          ),
                                                                                                        ]
                                                                                                      ),
                                                                                                    ]
                                                                                                  )
                                                                                                : _vm._e(),
                                                                                            ]
                                                                                          ),
                                                                                        ]
                                                                                      ),
                                                                                    ],
                                                                                    1
                                                                                  ),
                                                                                ],
                                                                                1
                                                                              )
                                                                            }
                                                                          ),
                                                                          0
                                                                        ),
                                                                        _c(
                                                                          "div",
                                                                          {
                                                                            staticClass:
                                                                              "col-12 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-xs-12 column",
                                                                          },
                                                                          _vm._l(
                                                                            _vm.localResources,
                                                                            function (
                                                                              resource
                                                                            ) {
                                                                              return _c(
                                                                                "div",
                                                                                {
                                                                                  key: resource.id,
                                                                                  staticClass:
                                                                                    "col-12 q-pa-sm",
                                                                                  staticStyle:
                                                                                    {
                                                                                      "min-width":
                                                                                        "70%",
                                                                                      "min-height":
                                                                                        "70%",
                                                                                    },
                                                                                },
                                                                                [
                                                                                  _c(
                                                                                    "q-card",
                                                                                    {
                                                                                      staticClass:
                                                                                        "text-primary text-left resourceCard",
                                                                                      attrs:
                                                                                        {
                                                                                          flat: "",
                                                                                        },
                                                                                    },
                                                                                    [
                                                                                      _c(
                                                                                        "q-card-section",
                                                                                        {
                                                                                          staticClass:
                                                                                            "resource-header",
                                                                                        },
                                                                                        [
                                                                                          _c(
                                                                                            "div",
                                                                                            {
                                                                                              staticClass:
                                                                                                "text-h5 text-bold",
                                                                                            },
                                                                                            [
                                                                                              _vm._v(
                                                                                                "\n                                    " +
                                                                                                  _vm._s(
                                                                                                    resource.organizationName
                                                                                                  ) +
                                                                                                  "\n                                  "
                                                                                              ),
                                                                                            ]
                                                                                          ),
                                                                                          _c(
                                                                                            "div",
                                                                                            {
                                                                                              staticClass:
                                                                                                "text-subtitle1",
                                                                                            },
                                                                                            [
                                                                                              _vm._v(
                                                                                                "\n                                    " +
                                                                                                  _vm._s(
                                                                                                    resource.description
                                                                                                  ) +
                                                                                                  "\n                                  "
                                                                                              ),
                                                                                            ]
                                                                                          ),
                                                                                        ]
                                                                                      ),
                                                                                      _c(
                                                                                        "q-separator",
                                                                                        {
                                                                                          staticClass:
                                                                                            "secondary",
                                                                                          attrs:
                                                                                            {
                                                                                              inset:
                                                                                                "",
                                                                                            },
                                                                                        }
                                                                                      ),
                                                                                      _c(
                                                                                        "q-card-section",
                                                                                        [
                                                                                          _c(
                                                                                            "div",
                                                                                            {
                                                                                              staticClass:
                                                                                                "column",
                                                                                            },
                                                                                            [
                                                                                              resource.officeName
                                                                                                ? _c(
                                                                                                    "div",
                                                                                                    {
                                                                                                      staticClass:
                                                                                                        "col",
                                                                                                    },
                                                                                                    [
                                                                                                      _c(
                                                                                                        "p",
                                                                                                        [
                                                                                                          _vm._v(
                                                                                                            "\n                                        " +
                                                                                                              _vm._s(
                                                                                                                resource.officeName
                                                                                                              ) +
                                                                                                              "\n                                      "
                                                                                                          ),
                                                                                                        ]
                                                                                                      ),
                                                                                                    ]
                                                                                                  )
                                                                                                : _vm._e(),
                                                                                              resource.address
                                                                                                ? _c(
                                                                                                    "div",
                                                                                                    {
                                                                                                      staticClass:
                                                                                                        "col",
                                                                                                    },
                                                                                                    [
                                                                                                      _c(
                                                                                                        "p",
                                                                                                        [
                                                                                                          _vm._v(
                                                                                                            "\n                                        " +
                                                                                                              _vm._s(
                                                                                                                resource.address
                                                                                                              ) +
                                                                                                              "\n                                        "
                                                                                                          ),
                                                                                                          _c(
                                                                                                            "br"
                                                                                                          ),
                                                                                                          _vm._v(
                                                                                                            "\n                                        " +
                                                                                                              _vm._s(
                                                                                                                resource.city
                                                                                                              ) +
                                                                                                              ",\n                                        " +
                                                                                                              _vm._s(
                                                                                                                resource.state
                                                                                                              ) +
                                                                                                              "\n                                        " +
                                                                                                              _vm._s(
                                                                                                                resource.zip
                                                                                                              ) +
                                                                                                              "\n                                      "
                                                                                                          ),
                                                                                                        ]
                                                                                                      ),
                                                                                                    ]
                                                                                                  )
                                                                                                : _vm._e(),
                                                                                              resource.phoneNumber
                                                                                                ? _c(
                                                                                                    "div",
                                                                                                    {
                                                                                                      staticClass:
                                                                                                        "col",
                                                                                                    },
                                                                                                    [
                                                                                                      _c(
                                                                                                        "p",
                                                                                                        [
                                                                                                          _vm._v(
                                                                                                            "\n                                        Direct:\n                                        "
                                                                                                          ),
                                                                                                          _c(
                                                                                                            "strong",
                                                                                                            [
                                                                                                              _vm._v(
                                                                                                                _vm._s(
                                                                                                                  resource.phoneNumber
                                                                                                                )
                                                                                                              ),
                                                                                                            ]
                                                                                                          ),
                                                                                                        ]
                                                                                                      ),
                                                                                                      resource.tollFreeNumber
                                                                                                        ? _c(
                                                                                                            "p",
                                                                                                            [
                                                                                                              _vm._v(
                                                                                                                "\n                                        Toll Free Number:\n                                        "
                                                                                                              ),
                                                                                                              _c(
                                                                                                                "strong",
                                                                                                                [
                                                                                                                  _vm._v(
                                                                                                                    _vm._s(
                                                                                                                      resource.tollFreeNumber
                                                                                                                    )
                                                                                                                  ),
                                                                                                                ]
                                                                                                              ),
                                                                                                            ]
                                                                                                          )
                                                                                                        : _vm._e(),
                                                                                                    ]
                                                                                                  )
                                                                                                : _vm._e(),
                                                                                              resource.websiteAddress
                                                                                                ? _c(
                                                                                                    "div",
                                                                                                    {
                                                                                                      staticClass:
                                                                                                        "col",
                                                                                                    },
                                                                                                    [
                                                                                                      _c(
                                                                                                        "p",
                                                                                                        [
                                                                                                          _c(
                                                                                                            "a",
                                                                                                            {
                                                                                                              staticClass:
                                                                                                                "dont-break-out",
                                                                                                              staticStyle:
                                                                                                                {
                                                                                                                  color:
                                                                                                                    "#00667e",
                                                                                                                },
                                                                                                              attrs:
                                                                                                                {
                                                                                                                  target:
                                                                                                                    "blank",
                                                                                                                  href: resource.websiteAddress,
                                                                                                                },
                                                                                                            },
                                                                                                            [
                                                                                                              _vm._v(
                                                                                                                "Website"
                                                                                                              ),
                                                                                                            ]
                                                                                                          ),
                                                                                                        ]
                                                                                                      ),
                                                                                                    ]
                                                                                                  )
                                                                                                : _vm._e(),
                                                                                            ]
                                                                                          ),
                                                                                        ]
                                                                                      ),
                                                                                    ],
                                                                                    1
                                                                                  ),
                                                                                ],
                                                                                1
                                                                              )
                                                                            }
                                                                          ),
                                                                          0
                                                                        ),
                                                                      ]
                                                                    ),
                                                                  ]
                                                                ),
                                                              ]
                                                            )
                                                          : _vm._e(),
                                                        _vm.topic == "moreInfo"
                                                          ? _c(
                                                              "q-card-section",
                                                              [
                                                                _vm.UserReport[
                                                                  _vm.expanded
                                                                ].MoreInfo
                                                                  ? _c(
                                                                      "div",
                                                                      {
                                                                        staticClass:
                                                                          "col-10 col-xl-10 col-lg-10 col-md-10 col-sm-12 col-xs-12 column dont-break-out",
                                                                      },
                                                                      [
                                                                        _vm._l(
                                                                          _vm
                                                                            .UserReport[
                                                                            _vm
                                                                              .expanded
                                                                          ]
                                                                            .MoreInfo,
                                                                          function (
                                                                            moreInfo
                                                                          ) {
                                                                            return _c(
                                                                              "div",
                                                                              {
                                                                                key: moreInfo,
                                                                                staticClass:
                                                                                  "text-h6 text-center q-pa-lg",
                                                                                staticStyle:
                                                                                  {
                                                                                    "font-size":
                                                                                      "16px",
                                                                                    "line-height":
                                                                                      "1.5",
                                                                                    color:
                                                                                      "#0c667d",
                                                                                    width:
                                                                                      "90%",
                                                                                  },
                                                                              },
                                                                              [
                                                                                _c(
                                                                                  "div",
                                                                                  {
                                                                                    domProps:
                                                                                      {
                                                                                        innerHTML:
                                                                                          _vm._s(
                                                                                            moreInfo
                                                                                          ),
                                                                                      },
                                                                                  }
                                                                                ),
                                                                              ]
                                                                            )
                                                                          }
                                                                        ),
                                                                        _c(
                                                                          "br"
                                                                        ),
                                                                      ],
                                                                      2
                                                                    )
                                                                  : _vm._e(),
                                                              ]
                                                            )
                                                          : _vm._e(),
                                                      ],
                                                      1
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            ),
                                      ],
                                      1
                                    )
                                  }),
                                  _c("q-separator"),
                                ],
                                2
                              ),
                            ],
                            1
                          ),
                        ])
                      : _vm._e(),
                  ]
                )
              : _vm._e(),
            _c(
              "q-dialog",
              {
                attrs: { persistent: "" },
                model: {
                  value: _vm.restart,
                  callback: function ($$v) {
                    _vm.restart = $$v
                  },
                  expression: "restart",
                },
              },
              [
                _c(
                  "q-card",
                  [
                    _c(
                      "q-card-actions",
                      { attrs: { align: "right" } },
                      [
                        _c("q-btn", {
                          directives: [
                            { name: "close-popup", rawName: "v-close-popup" },
                          ],
                          attrs: {
                            flat: "",
                            label: "X",
                            size: "1.25rem",
                            color: "primary",
                          },
                          on: {
                            click: function ($event) {
                              _vm.startover = false
                            },
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "q-card-section",
                      {
                        staticClass: "row items-center",
                        staticStyle: { "margin-top": "-30px" },
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "q-ma-none q-pa-none text-center",
                            staticStyle: { color: "#00667e", margin: "auto" },
                          },
                          [
                            _vm.language == "EN"
                              ? _c(
                                  "h5",
                                  {
                                    staticClass: "text-bold",
                                    staticStyle: { height: "0px" },
                                  },
                                  [
                                    _vm._v(
                                      "\n              Are you sure?\n            "
                                    ),
                                  ]
                                )
                              : _vm._e(),
                            _vm.language == "ES"
                              ? _c(
                                  "h5",
                                  {
                                    staticClass: "text-bold",
                                    staticStyle: { height: "0px" },
                                  },
                                  [
                                    _vm._v(
                                      "\n              ¿Estas seguro?\n            "
                                    ),
                                  ]
                                )
                              : _vm._e(),
                          ]
                        ),
                        _c(
                          "div",
                          { staticClass: "q-pa-none q-ma-sm text-center" },
                          [
                            _vm.language == "EN"
                              ? _c(
                                  "h5",
                                  {
                                    staticStyle: {
                                      color: "#00667e",
                                      "margin-block-start": "inherit",
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "\n              Upon restarting all of your current answers will be deleted. Are\n              you sure you want to restart?\n            "
                                    ),
                                  ]
                                )
                              : _vm._e(),
                            _vm.language == "ES"
                              ? _c(
                                  "h5",
                                  {
                                    staticStyle: {
                                      color: "#00667e",
                                      "margin-block-start": "inherit",
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "\n              Al reiniciar, se eliminarán todas sus respuestas actuales.\n              ¿Estás seguro de que quieres reiniciar?\n            "
                                    ),
                                  ]
                                )
                              : _vm._e(),
                          ]
                        ),
                      ]
                    ),
                    _c("q-separator", {
                      attrs: { color: "secondary", inlet: "" },
                    }),
                    _c(
                      "q-card-section",
                      { staticClass: "text-center text" },
                      [
                        _vm.language == "EN"
                          ? _c("q-btn", {
                              staticStyle: { margin: "auto" },
                              attrs: {
                                flat: "",
                                label: "Restart",
                                color: "primary",
                                size: "1.25rem",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.goBack()
                                },
                              },
                            })
                          : _vm._e(),
                        _vm.language == "ES"
                          ? _c("q-btn", {
                              staticStyle: { margin: "auto" },
                              attrs: {
                                flat: "",
                                label: "Reiniciar",
                                color: "primary",
                                size: "1.25rem",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.goBack()
                                },
                              },
                            })
                          : _vm._e(),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }